import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ali-privacy-and-cookies',
  templateUrl: './privacy-and-cookies.component.html',
  styleUrls: ['./privacy-and-cookies.component.css']
})
export class PrivacyAndCookiesComponent implements OnInit {
  @Input() show: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
