<mat-card class="image" matTooltipPosition="left"
          matTooltip="La Copertina serve ad aggiornare gli archivi anagrafici degli operatori del settore (Arianna, catene di librerie, siti internet…). DIMENSIONE MASSIMA: 3MB">

  <mat-card-title><h3 class="center">{{title}}</h3></mat-card-title>
  <button mat-icon-button style="float: right;" (click)="clear();" *ngIf="picture && !disabled">
    <mat-icon>clear</mat-icon>
  </button>

  <img [src]="picture  | safeResourceUrl" *ngIf="picture" onerror="this.src='assets/images/missing.png'"/>
  <img class="placeholder" src="../../../assets/images/missing.png" *ngIf="!picture" (click)="file.click()" onerror="this.src='assets/images/missing.png'"/>
  <input type="file" accept="image/jpeg" name="preview" id="preview" #file class="hide"
         (change)="loadFile($event)" [disabled]="disabled">

<!--  COMMENTATA 01/02/2021-->
<!--  <mat-card-subtitle *ngIf="picture">{{w}}px X {{h}}px - {{size.size}}</mat-card-subtitle>-->

  <!--    <button mat-button class="full-width" color="primary" (click)="file.click()">-->
  <!--        Seleziona immagine-->
  <!--        <mat-icon>attach_file</mat-icon>-->
  <!--        <input type="file" accept="image/jpeg" name="preview" id="preview" #file class="hide"-->
  <!--               (change)="loadFile($event)">-->
  <!--    </button>-->

</mat-card>
