import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Giro } from 'src/app/models/giro.model';
import { iUser } from 'src/app/models/user.model';
import { BookService } from 'src/app/services/book.service';
import { MessageService } from 'src/app/services/message.service';
import { LocalStorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-giri-cliente',
  templateUrl: './giri-cliente.component.html',
  styleUrls: ['./giri-cliente.component.scss']
})
/** prendere anche i giri passati dal database e aggiungere 4/5 giorni alla data di scadenza */
  /** alla selezione del giro deve mostrare il componente document-download */
export class GiriClienteComponent implements OnInit { 
  loading = false;
  user: iUser;
  oggi = new Date().toISOString();
  @Input() giri:Array<Giro>;
  giroselezionato: Giro;
  constructor(
    private storage: LocalStorageService,
    private bookservice: BookService
  ) { }

  ngOnInit(): void {
      this.user = new iUser(this.storage.get('usr'));  
      //this.getGiri();
  }

  /* async getGiri() {
    this.loading = true;
    this.bookservice.getGiriByYear(moment().format('YYYY'),1).subscribe((data: Array<Giro>) => {
        this.loading = false;
        this.giri = data;
        this.giroselezionato = this.giri[0];
    });
  } */

  formatDataConsegna(data){
    const giorno = moment(data).add(5 ,'d').format('DD');
    return giorno + '/'+ moment(data).add(6 ,'d').format('DD-MM-YYYY');
  }

  formatDataInvio(data){
    return moment(data).subtract(1 ,'d').format('YYYY-MM-DD');
  }

  isSelected(giro:Giro, giri) {
    /* const i = giri.findIndex((giro) =>
      moment(giro.data_scadenza).add(5 ,'d').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
    );  */
    //giro.data_fine_prenotazione 
    const i = giri.findIndex(giro => moment(giro.data_fine_prenotazione).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD'));
    if ( giri[i] && giro.descrizione === giri[i].descrizione) {
      this.giroselezionato = giri[i];
      this.storage.set('selectedGiro',this.giroselezionato);
      return 'rowTr selected';
    } 
    return 'rowTr';
  }
}
