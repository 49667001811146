import { Component, OnDestroy, OnInit } from '@angular/core';
import { Giro } from 'src/app/models/giro.model';
import { BookService } from 'src/app/services/book.service';
import * as moment from 'moment';
import {findLastIndex} from 'lodash';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-download-schede',
  templateUrl: './download-schede.component.html',
  styleUrls: ['./download-schede.component.scss']
})
export class DownloadSchedeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  public loader = {
    pdf : false,
  };
  giro: Giro = null;

  constructor(private bookservice:BookService) { }

  ngOnInit(): void {
    this.giroAttuale();
  }

  /**Download schede novita con richiesta stampa = 1 */
  async downloadSchede(){
    //console.log(this.giro);
    this.loader.pdf = true;
    const sub = this.bookservice.donwloadRichSchede(Number(this.giro.anno),Number(this.giro.numero)).subscribe((data:any) => { 
      const blob = new Blob([data], {type: 'application/pdf'});
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = `novita_richieste_${this.giro.anno}_${this.giro.numero}.pdf`;
      a.click();
      this.loader.pdf = false;
    });

    this.subscriptions.push(sub);
  }


  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }

  async giroAttuale() {
    let giro = null;
    this.loader.pdf = true
    const sub = this.bookservice.getGiriByYear(moment().format('YYYY'),1).subscribe((data: Array<Giro>) => {
      //cerco l'ultimo giro con la data minore di oggi
      const i = findLastIndex(data, giro => { return moment(giro.data_scadenza).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') });
      this.giro = data[i];
      this.loader.pdf = false
    });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }

}
