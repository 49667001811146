<div
  class="container-fluid"
  style="height: auto; overflow-y: hidden"
>
  <div class="row justify-content-around">
    <div class="col-5 padding">
      <mat-card
        style="
          max-width: 900px;
          max-height: 600px;
          overflow-y: auto;
        "
        align="center"
      >
        <mat-card-header>
          <mat-card-title>{{
            findActiveStep().title
          }}</mat-card-title>
          <mat-card-subtitle
            >Prima di inserire dei contenuti, assicurati che
            il nome del file corrsisponda ad un ISBN di un
            titolo esistente. Es.
            9788855470483.jpg</mat-card-subtitle
          >
        </mat-card-header>
        <!--START IMMAGINI-->
        <mat-card-content *ngIf="steps[0].isActive">
          <!-- <form> -->
          <input
            type="file"
            multiple
            #file
            style="display: none"
            (change)="setFiles($event)"
            accept="image/png, image/jpeg, image/jpg, application/pdf"
          />

          <button
            matSuffix
            mat-raised-button
            color="accent"
            (click)="file.click()"
          >
            Inserisci file
            <mat-icon style="font-size: 24px"
              >upload_file</mat-icon
            >
          </button>
          <!-- </form> -->
        </mat-card-content>
        <!--END IMMAGINI-->

        <!--START CONFERMA-->
        <mat-spinner
          *ngIf="
            fileNumber !==
            this.invalidFile.length + this.validFile.length
          "
          diameter="40"
        ></mat-spinner>

        <mat-card-content
          *ngIf="
            steps[1].isActive &&
            fileNumber ===
              this.invalidFile.length +
                this.validFile.length
          "
        >
          <mat-list>
            <ng-container *ngIf="this.validFile.length > 0">
              <div mat-subheader>Validi</div>
              <mat-list-item *ngFor="let file of validFile">
                <mat-icon mat-list-icon
                  >check_circle</mat-icon
                >
                <div mat-line>{{ file.name }}</div>
                <div mat-line>
                  {{ this.formatBytes(file.size) }}
                </div>
              </mat-list-item>
            </ng-container>

            <ng-container
              *ngIf="this.invalidFile.length > 0"
            >
              <mat-divider></mat-divider>
              <div mat-subheader>Non validi</div>
              <mat-list-item
                *ngFor="let file of invalidFile"
              >
                <mat-icon mat-list-icon
                  >highlight_off</mat-icon
                >
                <div mat-line>{{ file.name }}</div>
                <div mat-line>
                  {{ this.formatBytes(file.size) }}
                </div>
              </mat-list-item>
            </ng-container>
          </mat-list>

          <mat-card-actions>
            <button
              mat-button
              color="accent"
              (click)="confirmUpload()"
              [disabled]="validFile.length === 0"
            >
              Conferma
            </button>
            <button
              mat-button
              color="warn"
              (click)="undoUpload()"
            >
              Annulla
            </button>
          </mat-card-actions>
        </mat-card-content>
        <!--END CONFERMA-->

        <!--START UPLOAD-->
        <mat-card-content *ngIf="steps[2].isActive">
          <mat-progress-bar
            [value]="this.percent"
          ></mat-progress-bar>
        </mat-card-content>
        <!--END UPLOAD-->

        <mat-card-content *ngIf="wooUpload">
            <span>Stiamo Aggiornando la copertina su WooCommerce</span>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </mat-card-content>

        <mat-card-content *ngIf="steps[3].isActive && !wooUpload">
          <p>Inserimento completato</p>
          <mat-card-actions>
            <button
              mat-button
              color="accent"
              (click)="updateStepState(0)"
            >
              Continua ad inserire
            </button>
          </mat-card-actions>
        </mat-card-content>
        <!--END FINAL-->
      </mat-card>

      <!-- <div class="container-fluid" align="center" *ngIf="validFile.length > 0 || invalidFile.length > 0 ">
            <mat-card style="max-width: 900px;">
                <mat-card-header>
                    <mat-card-title>Controlla i file da inserire</mat-card-title>
                </mat-card-header>
                
            </mat-card>
        </div> -->
    </div>

    <div class="col-5 padding">
      <app-audio></app-audio>
    </div>
  </div>
</div>
