import { MessageService } from './../../services/message.service';
import { dataTable } from './../../models/dataTable.model';
import { environment } from './../../../environments/environment';
import { BookService } from './../../services/book.service';
import { iUser, User } from './../../models/user.model';
import { Router } from '@angular/router';
import { LocalStorageService } from './../../services/storage.service';
import { SuccessOautResponse } from './../../models/oauth.model';
import { OauthService } from './../../services/oauthservice.service';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { ToastrService } from 'ngx-toastr';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { PrivacyAndCookiesComponent } from '../privacy-and-cookies/privacy-and-cookies.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher
  implements ErrorStateMatcher
{
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadein', [
      state('void', style({ opacity: 0 })),
      transition('void => *', [
        style({ opacity: 0 }),
        animate('1000ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  userName = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  public form;
  public formLostPwd;
  loading = false;
  user: iUser;
  version = environment.version;
  showResetPwd = false;
  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private oauth: OauthService,
    private localstore: LocalStorageService,
    private router: Router,
    private bookservice: BookService,
    private toast: ToastrService,
    private messageservice: MessageService,
    public dialog: MatDialog
  ) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.formLostPwd = fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {}

  resetPwd(): void {
    const sub = this.oauth
      .forgotPassword(
        this.formLostPwd.value.email,
        'editore'
      )
      .subscribe(
        (res: SuccessOautResponse) => {
          this.toast.success(
            'Richiesta di cambio password inviata. Controlla la tua casella e-mail.'
          );
          this.formLostPwd.reset();
        },
        (err: any) => {
          // this.toastr.warning(err.error.message);
        }
      );

    this.subscriptions.push(sub);
  }

  login(): void {
    this.loading = true;
    this.localstore.clear();
    const sub = this.oauth
      .login(
        this.form.value.email,
        this.form.value.password
      )
      .subscribe(
        (res: SuccessOautResponse) => {
          this.localstore.set('token', res);
          this.localstore.set(
            'tokenTimestamp',
            Math.floor(Date.now() / 1000)
          );
          this.setUser(res.access_token);
        },
        (err: any) => {
          this.loading = false;
          if (err.error.error === 'invalid_request') {
            this.toast.warning(
              'Inserire username e password',
              'Controllare i campi obbligatori'
            );
          } else if (err.error.error === 'invalid_grant') {
            this.toast.error(
              'Le credenziali inserite non sono valide',
              'Credenziali errate'
            );
          } else {
            this.toast.error(err.error.error);
          }
        }
      );

    this.subscriptions.push(sub);
  }

  async saveBooks() {
    if (
      this.user.isEditore() ||
      this.user.isAdmin() ||
      this.user.isCliente()
    ) {
      const cache = await caches.open(environment.cache);

      cache.delete(this.bookservice.catalogoRequest());
      cache.delete(
        this.bookservice.getCollectionCached('collane')
      );
      cache.delete(
        this.bookservice.getCollectionCached('generi')
      );
      cache.delete(
        this.bookservice.getCollectionCached('argomenti')
      );
      cache.delete(
        this.bookservice.getCollectionCached('rilegature')
      );
      cache.delete(
        this.bookservice.getCollectionCached('lingue')
      );
      cache.delete(
        this.bookservice.getCollectionCached('categorie')
      );

      cache.add(
        this.bookservice.getCollectionCached('collane')
      );
      cache.add(
        this.bookservice.getCollectionCached('generi')
      );
      cache.add(
        this.bookservice.getCollectionCached('argomenti')
      );
      cache.add(
        this.bookservice.getCollectionCached('rilegature')
      );
      cache.add(
        this.bookservice.getCollectionCached('lingue')
      );
      cache.add(
        this.bookservice.getCollectionCached('categorie')
      );
      if (this.user.isEditore()) {
        cache
          .add(this.bookservice.catalogoRequest())
          .then(() => {
            this.messageservice.setEvent({
              event: 'catalogo-loaded',
            });
            this.localstore.set('catalogo-loaded', true);
            // console.log('ho finito di caricare il catalogo');
          });
      }
    }
  }

  setUser(token) {
    const sub = this.oauth
      .getUser(token)
      .subscribe((r: User) => {
        if (r.profile !== 'cliente') {
          this.localstore.set('usr', r);
          this.user = new iUser(r);
          this.router.navigateByUrl('');
          this.saveBooks();
        } else {
          this.toast.warning(
            'Il tuo profilo non è abilitato ad accedere a questo portale',
            'Profilo non abilitato'
          );
        }
      });

    this.subscriptions.push(sub);
  }

  openDialog() {
    const dialogRef = this.dialog.open(
      PrivacyAndCookiesComponent
    );
  }

  ngOnDestroy(): void {
    Utilities.unusubscribeAll(this.subscriptions);
  }
}
