import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { BookService } from 'src/app/services/book.service';
import { DeleteQuestionComponent } from '../delete-question/delete-question.component';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss'],
})
export class MediaDialogComponent
  implements OnInit, OnDestroy
{
  subscription: Subscription = null;

  constructor(
    public dialogRef: MatDialogRef<MediaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private bookservice: BookService,
    private toastservice: ToastrService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  onUndoClick() {
    this.data.delete = false;
    this.dialogRef.close();
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      'Bytes',
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
      ' ' +
      sizes[i]
    );
  }

  deleteAudio(audio) {
    let dialogRef = this.dialog.open(
      DeleteQuestionComponent,
      {
        data: {
          delete: true,
          titolo: audio.file_name,
          dialogTitle: 'Elimina la traccia audio',
          text: 'Vuoi davvero eliminare la traccia audio',
        },

        height: 'auto',
        width: '500px',
      }
    );

    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data && data.delete) {
          this.subscription = this.bookservice
            .deleteAudio(this.data.ean, audio.uuid)
            .subscribe(
              (response: any) => {
                const index = this.data.audio.findIndex(
                  (item) => {
                    return item.uuid === audio.uuid;
                  }
                );

                this.data.audio.splice(index, 1);
                this.toastservice.success(
                  'Traccia audio eliminata con successo'
                );
              },
              (error) => {
                this.toastservice.error(
                  "Errore durante l'eliminazione, traccia non eliminata"
                );
              }
            );
          //this.onUndoClick();
        }
      });
  }

  ngOnDestroy(): void {
      Utilities.unsubscribe(this.subscription);
  }
}
