import {Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.css']
})
export class CookieBarComponent implements OnInit {
  @Output() openPolicy = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

}
