import { Utilities } from './../../utilities/utilities.class';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() title = '';
  @Output() changeFile = new EventEmitter<object>();
  @Input() picture = null;
  @Input() disabled:boolean = false;
  h = null;
  w = null;
  size = {
    size: '',
    valid: false
  };

  constructor(
      private toast: ToastrService,
  ) {
  }

  ngOnInit(): void {
    if (this.picture) {
      const img = new Image();
      let _s = this;
      img.src = this.picture;
      img.onload = function () {
        _s.w = img.width;
        _s.h = img.height;
      }
    }

  }

  getBase64(file): void {
    const reader = new FileReader();
    reader.readAsDataURL(file.files[0]);
    const _s = this;
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      _s.picture = 'data:image/jpeg;base64,' + encoded;
      file.value = null;

    },
        reader.onerror = (error) => {
          console.log('Error: ', error);
        };
  }

  clear() {
    this.picture = null;
    this.changeFile.emit(null);
  }


  loadFile(event): void {
    const img = new Image();
    let _s = this;
    img.onload = () => {
      _s.w = img.width;
      _s.h = img.height;
    };

    this.formatBytes(event.target.files[0].size);
    if (this.size.valid) {
      img.src = URL.createObjectURL(event.target.files[0]);
      this.changeFile.emit(event.target.files[0]);
      this.getBase64(event.target);
    } else {
      this.toast.warning('Dimensione massima: 3MB', 'Il file caricato è troppo grande');
    }
    /* 
    const output: any = document.getElementById('preview');
    output.src = URL.createObjectURL(event.target.files[0]);
    console.log(output.width, output.height); 
    */
  }


  /**
   *
   * @param bytes
   * @param decimals
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    let tooBig = true;
    const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    if (i > 1 && size > 3){
      tooBig = false;
    }
    this.size.size = size + sizes[i];
    this.size.valid = tooBig;
  }
}
