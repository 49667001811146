/* export interface Giro {
    data_scadenza: string;
    data_inizio_prenotazione: string;
    data_fine_prenotazione: string;
    data_fine_distribuzione: string;
    mesi_consigliati: string;
    descrizione: string;
} */
/**
 * code: "903"
created_at: "2021-02-11T14:56:38.000000Z"
editore: "REN BOOKS"
email: "paoloantonioreina@gmail.com"
id: 1
name: "paoloantonioreina"
partizioni: [{EP_EDITORE: "903", EP_PARTIZIONE: "97888906262", EP_FIRST: "1"},…]
profile: "editore"
updated_at: "2021-02-11T14:56:38.000000Z"
*/
 

export interface Partizioni {
    EP_EDITORE: string;
    EP_PARTIZIONE: string;
    EP_FIRST: string;
}

export interface User {
    id: number;
    name: string;
    email: string;
    code: string;
    profile: string;
    created_at: Date;
    updated_at: Date;
    editore?: string;
    partizioni?: Partizioni[];
}
export class iUser implements User {
    id: number;
    name: string;
    email: string;
    code: string;
    profile: string;
    created_at: Date;
    updated_at: Date;
    editore?: string;
    partizioni?: Partizioni[];

    constructor(user: User) {
        Object.assign(this, user);
    }

    public isEditore() {
        if (this.profile==='editore') {
            return true;
        }
        return false;
    }

    public isAdmin():boolean {
        if (this.profile==='admin') {
            return true;
        }
        return false;
    }

    public isCliente():boolean {
        if (this.profile==='cliente') {
            return true;
        }
        return false;
    }

    public getName(): string {
        if (this.profile === 'editore') {
            return this.editore;
        }

        return this.name;
    }
}