<mat-card class="scheda">
  <mat-card-title
    ><h3 class="center">{{ title }}</h3></mat-card-title
  >

  <mat-card-content class="p-3">
    <button
      class="m-1"
      mat-mini-fab
      color="primary"
      matTooltip="Aggiungi immagine (max. {{ max }})"
      (click)="file.click()"
      [disabled]="images.length >= max"
      *ngIf="!insert"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      class="m-1"
      mat-mini-fab
      color="primary"
      matTooltip="Rimuovi immagine"
      (click)="removeImage()"
      *ngIf="!insert"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <input
      #file
      type="file"
      accept="image/*"
      (change)="addImage($event)"
      class="hide"
    />

    <div
      class="imageContainer center"
      *ngFor="let image of images; let i = index"
    >
      <img [src]="image" class="image" />
      <!--
            <button mat-icon-button aria-label="Elimina immagine">
                <mat-icon>remove_circle</mat-icon>
            </button>
            -->
    </div>
  </mat-card-content>
</mat-card>
