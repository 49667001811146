import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "lodash";

@Component({
  selector: "app-view-order",
  templateUrl: "./view-order.component.html",
  styleUrls: ["./view-order.component.scss"],
})
export class ViewOrderComponent implements OnInit {
  rows = [];
  @Input() data = [];

  dataSource;
  //articoloTools: ArtanyicoloTools = new ArticoloTools();
  displayedColumns: string[] = [
    "nr_ordine",
    "nr_causale",
    "data_ordine",
    "ragione_sociale",
    /* "stato_articolo", */
    "qta_ordine",
   
  ];

  columnsName: string[] = [
    "Numero Ordine",
    "Numero Causale",
    "Data Ordine",
    "Ragione Sociale",
   /*  "Stato Articolo", */
    "Qta. Ordine",
  ]

  constructor() {}

  ngOnInit(): void {
    this.data =  _.orderBy(this.data, ["data_ordine"], ["disc"])
    this.rows = this.findSconto(this.data);
    this.dataSource = new MatTableDataSource(this.data);
  }

  findSconto(data) {
    //raggruppo per numero ordine
    const groupedOrders = _.groupBy(data, "id_ordine");
    let rows = [];
    Object.keys(groupedOrders).forEach((key) => {
      //sommo tutti gli ordini omaggio
      let giftedOrders = _.filter(groupedOrders[key], ["sconto_base", "99"]);
      let totalGiftedQuantity = _.sumBy(giftedOrders, function (o) {
        return Number(o.qta_rimanenza);
      });
      //sommo tutti gli ordini non in omaggio
      let regularOrders = _.filter(groupedOrders[key], function (o) {
        return Number(o.sconto_base) < 99;
      });
      let totalRegularQuantity = _.sumBy(regularOrders, function (o) {
        return Number(o.qta_rimanenza);
      });
      //concateno il valore finale
      groupedOrders[key][0].qta_rimanenza =
        totalGiftedQuantity && totalRegularQuantity
          ? `${totalRegularQuantity}+${totalGiftedQuantity} omaggio`
          : groupedOrders[key][0].qta_rimanenza;
      groupedOrders[key][0].sconto_base = "40";
      rows.push(groupedOrders[key][0]);
    });

    return rows;
  }
}
