<mat-toolbar [color]="color" class="toolbar">
  <span class="logo">
    <img src="./../../../assets/ico-48.png" />
  </span>
  <span>UNICOPLI Distributore</span>
  <span class="spacer"></span>
  <span style="font-size: 14px">{{ user.getName() }}</span>
  <button mat-button routerLink="profilo">
    {{ user.email }}
    <mat-icon>account_circle</mat-icon>
  </button>
  <button mat-button routerLink="help" (click)="help()">
    <mat-icon>help</mat-icon>
  </button>

  <button mat-icon-button aria-label="Esci" (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
  </button>
</mat-toolbar>
<mat-tab-group
  id="sticky"
  [color]="color"
  *ngIf="enableMenu"
  class="mt64"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
>
  <mat-tab label="Dashboard">
    <app-dashboard [user]="user"></app-dashboard>
  </mat-tab>
  <mat-tab label="Catalogo">
    <app-catalogo
      *ngIf="selected.value === 1 && user.isEditore()"
    ></app-catalogo>
    <admin-catalogo
      *ngIf="selected.value === 1 && user.isAdmin()"
    ></admin-catalogo>
    <app-catalogo-cliente
      *ngIf="selected.value === 1 && user.isCliente()"
    ></app-catalogo-cliente>
  </mat-tab>
  <mat-tab label="Novità">
    <app-lanci
      [giro]="giro"
      *ngIf="selected.value === 2 && user.isEditore()"
    ></app-lanci>
    <admin-novita *ngIf="selected.value === 2 && user.isAdmin()"></admin-novita>
    <app-novita-cliente
      *ngIf="selected.value === 2 && user.isCliente()"
    ></app-novita-cliente>
  </mat-tab>
  <mat-tab label="Portafoglio Ordini" *ngIf="user.isEditore()">
    <app-portafoglio-ordini></app-portafoglio-ordini>
  </mat-tab>
  <mat-tab label="Statistiche" *ngIf="!user.isCliente()">
    <app-statistiche
      *ngIf="selected.value === 3"
      [user]="user"
    ></app-statistiche>
  </mat-tab>

  <mat-tab label="Inserimento contenuti" *ngIf="user.isAdmin()">
    <app-inserimento-contenuti
      *ngIf="selected.value === 4"
    ></app-inserimento-contenuti>
  </mat-tab>
</mat-tab-group>
