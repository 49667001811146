import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-nuova-collana",
  templateUrl: "./nuova-collana.component.html",
  styleUrls: ["./nuova-collana.component.scss"],
})
export class NuovaCollanaComponent implements OnInit {

  collana = '';

  constructor(
    public dialogRef: MatDialogRef<NuovaCollanaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
}
