import { MessageService } from 'src/app/services/message.service';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BookService } from 'src/app/services/book.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';
@Component({
  selector: 'app-modifica-catalogo',
  templateUrl: './modifica-catalogo.component.html',
  styleUrls: ['./modifica-catalogo.component.scss'],
})
export class ModificaCatalogoComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() code: string = null;
  @Input() book: any = null;
  @Input() process = false;

  @Output() close = new EventEmitter<boolean>();

  public loading = true;
  private copertina = new FormData();
  private schedaNovita = new FormData();
  private additionalImages = new FormData();

  immagineCopertina = null;
  subscriptions: Subscription[] = [];
  novita = null;
  images = [];
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'bold',
        'customClasses',
        'insertImage',
        'insertVideo',
        'link',
        'unlink',
        'toggleEditorMode',
        'clearFormatting',
        'subscript',
        'superscript',
        'aepicker',
      ],
    ],
  };

  constructor(
    private bookservice: BookService,
    private toastservice: ToastrService,
    private domSanitizer: DomSanitizer,
    private messageservice: MessageService
  ) {}

  ngOnInit(): void {
    this.getBookDetail(this.code);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.process.previousValue &&
      changes.process.currentValue
    ) {
      this.submit();
    }
  }

  setCopertina($event): void {
    this.copertina.delete('image');
    this.copertina.append('image', $event);
  }
  setPdf($event, tipo: string): void {
    this.schedaNovita.delete('scheda');
    this.schedaNovita.append('scheda', $event);
  }
  /* setAdditionalImages($event): void {  
    this.additionalImages.delete('images[]');
    this.images.push($event);
    for (let image of this.images){
      this.additionalImages.append('images[]', image); 
    }
  } */

  setAdditionalImages($event): void {
    if ($event === 0) {
      this.images.pop();
      if (this.images.length === 0) {
        this.additionalImages.delete('images[]');
      }
    } else {
      this.images.push($event);
    }
    this.additionalImages.delete('images[]');
    for (let image of this.images) {
      this.additionalImages.append('images[]', image);
    }
  }
  getBookDetail(code) {
    this.loading = true;
    const sub = this.bookservice.getBook(code).subscribe(
      (data: any) => {
        this.book = data;
        this.loading = false;
        let request = this.bookservice.imageRequest(
          this.book.ean,
          'mini'
        );
        fetch(request, { cache: 'reload' })
          .then((response) => response.blob())
          .then((blob) => {
            this.immagineCopertina =
              URL.createObjectURL(blob);
          });
        request = this.bookservice.schedaNovitaRequest(
          this.book.ean
        );
        fetch(request, { cache: 'reload' }).then(
          (response) => {
            if (response.status != 404) {
              response.blob().then((blob) => {
                this.novita =
                  this.domSanitizer.bypassSecurityTrustResourceUrl(
                    URL.createObjectURL(blob)
                  );
              });
            }
          }
        );
      },
      (error: any) => {
        this.bookservice.hadleError(error);
      }
    );

    this.subscriptions.push(sub);
  }
  saveCopertina(ean) {
    if (this.copertina.has('image')) {
      this.messageservice.setEvent({
        type: 'loadImage',
        event: { ean: ean },
      });
      const sub = this.bookservice
        .insertCopertina(this.copertina, ean, 1)
        .subscribe(
          (data) => {
            this.toastservice.info(
              'Immagine copertina inserita con successo'
            );
            this.messageservice.setEvent({
              type: 'reloadImage',
              event: { ean: ean },
            });
          },
          (error) => {
            this.toastservice.error(
              "Si sono verificati degli errori durante il caricamento dell'immagine di copertina"
            );
          }
        );

      this.subscriptions.push(sub);
    }
  }

  saveSchedaNovita(ean) {
    if (this.schedaNovita.has('scheda')) {
      const sub = this.bookservice
        .insertSchedaNovita(this.schedaNovita, ean, 1)
        .subscribe(
          (data: any) => {
            this.toastservice.info(
              'Scheda novità inserita con successo'
            );
          },
          (error) => {
            this.toastservice.error(
              "Si sono verificati degli errori durante l'inserimento della scheda novità"
            );
          }
        );

      this.subscriptions.push(sub);
    }
  }

  saveAdditionalImages(ean) {
    if (this.additionalImages.has('images[]')) {
      const sub = this.bookservice
        .insertAdditionalImages(
          this.additionalImages,
          ean,
          1
        )
        .subscribe(
          (data) => {
            this.toastservice.info(
              'Immagini inserite con successo'
            );
          },
          (error) => {
            this.toastservice.error(
              'Si sono verificati degli errori durante il caricamento delle immagini'
            );
          }
        );

      this.subscriptions.push(sub);
    }
  }

  deleteAdditionalImages(id) {
    const sub = this.bookservice
      .deleteAdditionalImages(id)
      .subscribe((data) => {
        console.log(data);
      });

    this.subscriptions.push(sub);
  }

  public submit() {
    const payload = {
      abstract: this.book.abstract,
      note: this.book.note,
    };
    const sub = this.bookservice
      .saveCatalogoBook(payload, this.book.cod)
      .subscribe((data) => {
        this.toastservice.success(
          'Modifiche caricate con successo'
        );
        this.saveCopertina(this.book.ean);
        if (this.additionalImages.has('images[]')) {
          this.saveAdditionalImages(this.book.ean);
        } else {
          this.deleteAdditionalImages(this.book.ean);
        }
        this.saveSchedaNovita(this.book.ean);
        this.close.emit(true);
      });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    Utilities.unusubscribeAll(this.subscriptions);
  }
}
