import {
  iUser,
  Partizioni,
} from './../../models/user.model';
import { MessageService } from 'src/app/services/message.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Giro } from './../../models/giro.model';
import { NuovoAutoreComponent } from './../nuovo-autore/nuovo-autore.component';
import { description } from 'src/app/models/books.model';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from './../../services/storage.service';
import { AutoreComponent } from './../../components/autore/autore.component';
import { BookService } from './../../services/book.service';
import { Wbook, WBookEntity } from './../../models/wbook';
import {
  Component,
  EventEmitter,
  Input,
  ModuleWithComponentFactories,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NuovaCollanaComponent } from '../nuova-collana/nuova-collana.component';
import { Utilities } from 'src/app/utilities/utilities.class';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'book-form',
  templateUrl: './book-form.component.html',
  styleUrls: ['./book-form.component.scss'],
})
export class BookFormComponent implements OnInit, OnDestroy {
  @Input() book: Wbook = new Wbook();
  @Input() oid: number = 0;
  @Input() ristampa: boolean = false;
  @Input() viewOnly: boolean = false;

  disabilitaModifiche = false;
  subscriptions: Subscription[] = [];
  loading = true;
  immagineCopertina = null;
  images = [];
  novita = null;
  collane: Array<description>;
  autoriSelected: Array<description> = [];
  collaboratoriSelected: Array<any> = [];
  generi: Array<description>;
  categorie: Array<description>;
  argomenti: Array<description>;
  lingue: Array<description>;
  rilegature: Array<description>;
  mesiUscita: Array<description> = [];
  loadingAutori = false;
  //argomentiSelected: Array<description> = [];
  defaultCodiciAutori = [];
  defaultCollaboratori = {
    curatore: [],
    illustratore: [],
    traduttore: [],
    prefazione: [],
  };
  autore;
  steps = 0;
  nsteps = 6;
  errors = {
    richiestastampa: false,
  };
  annoEdizione = moment().format('Y');
  meseEdizione;
  aliquotaIva: description;
  tags = [];
  defaultAutori: Array<description>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  tabellaIva = [
    { cod: '101', iva: 74 },
    { cod: '102', iva: 74 },
    { cod: '103', iva: 22 },
    { cod: '104', iva: 22 },
    { cod: '105', iva: 22 },
    { cod: '106', iva: 22 },
    { cod: '107', iva: 22 },
    { cod: '108', iva: 22 },
    { cod: '109', iva: 22 },
    { cod: '110', iva: 22 },
    { cod: '111', iva: 22 },
    { cod: '112', iva: 22 },
    { cod: '113', iva: 22 },
    { cod: '114', iva: 22 },
    { cod: '115', iva: 22 },
    { cod: '116', iva: 22 },
    { cod: '117', iva: 22 },
    { cod: '118', iva: 22 },
    { cod: '140', iva: 22 },
    { cod: '150', iva: 22 },
    { cod: '154', iva: 5 },
    { cod: '180', iva: 74 },
  ];

  @Output() OnClickNovita = new EventEmitter<boolean>();
  @Output() OnBookInserted = new EventEmitter<number>();

  private copertina = new FormData();
  private schedaNovita = new FormData();
  private additionalImages = new FormData();
  isbn: string = null;

  /**@todo spostare in un servizio o scrivere un file json tipo cod: descrizione */
  public mesi = [
    { cod: 1, name: 'gennaio' },
    { cod: 2, name: 'febbraio' },
    { cod: 3, name: 'marzo' },
    { cod: 4, name: 'aprile' },
    { cod: 5, name: 'maggio' },
    { cod: 6, name: 'giugno' },
    { cod: 7, name: 'luglio' },
    { cod: 8, name: 'agosto' },
    { cod: 9, name: 'settembre' },
    { cod: 10, name: 'ottobre' },
    { cod: 11, name: 'novembre' },
    { cod: 12, name: 'dicembre' },
  ];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'bold',
        'customClasses',
        'insertImage',
        'insertVideo',
        'link',
        'unlink',
        'toggleEditorMode',
        'clearFormatting',
        'subscript',
        'superscript',
        'aepicker',
      ],
    ],
  };
  disabledConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };

  storageData: Giro = null;
  public formTitle = '';
  firstGiro: Giro = null;
  action = null;
  isbnOptions: string[] = [];
  filteredOptions: Observable<string[]>;
  isbnControl = new FormControl('');
  editFull = true;
  otherImages = [];

  modalitaStampaData = null;
  usr: iUser;
  constructor(
    public dialog: MatDialog,
    private storage: LocalStorageService,
    private bookservice: BookService,
    private toast: ToastrService,
    private domSanitizer: DomSanitizer,
    private messageservice: MessageService
  ) {
    this.usr = this.storage.get('usr');

    if (this.usr.partizioni) {
      this.usr.partizioni.forEach((partizione) => {
        this.isbnOptions.push(partizione.EP_PARTIZIONE);
      });
    }
    this.filteredOptions =
      this.isbnControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    //aggiungo il validatore al FormControl dell'isbn
    this.isbnControl.setValidators(
      this.isbnFieldValidator()
    );
    this.isbnControl.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.loading = true;
    this.getCollane();
    this.getCategorie();
    this.getGeneri();
    this.getLingue();
    this.getRilegature();
    this.getArgomenti();
    this.firstGiro = this.storage.get('firstGiro');
    this.storageData = this.storage.get('giro');

    /*  const mesi = this.storageData.mesi_consigliati.split(",");
    mesi.forEach(element => {
      const meseIndex = _.findIndex(this.mesi, ['name', element]);  
      this.mesiUscita.push({cod: `${this.storageData.anno}${meseIndex}`, descrizione: `${element} ${this.storageData.giro.anno}`});
    });
 */
    if (this.oid) {
      this.loadBook(this.oid);
      this.action = 'edit';
      this.formTitle = 'Modifica novità';
    } else {
      this.book.nuGiro = Number(this.storageData.numero);
      this.book.annoGiro = Number(this.storageData.anno);
      this.action = 'insert';
      this.formTitle = 'Inserimento novità';
      this.modalitaStampaData = new Date(
        this.storageData.data_scadenza
      );
    }

    if (this.ristampa) {
      this.book.nuGiro = Number(this.storageData.numero);
      this.book.annoGiro = Number(this.storageData.anno);
      this.action = 'insert';
      this.formTitle = 'Ristampa';
      this.makeDefaultAuthors();
      this.makeDefaultCollaboratori();
      this.disabilitaModifiche = true;
      this.getTabellaIva(false);
      this.loadCopertina();
      this.loadSchedaNovita();
    }
    if (this.usr.profile === 'admin') {
      this.book.editore = this.storage.get(
        'selectedEditore'
      );
      this.getPartizioni();
    }
    if (this.book.upload > 0) {
      // esiste la copertina del libro.
      const request = this.bookservice.imageRequest(
        this.book.isbn.toString(),
        'mini'
      );
      fetch(request, { cache: 'reload' })
        .then((response) => response.blob())
        .then((blob) => {
          this.immagineCopertina =
            URL.createObjectURL(blob);
        });
    }

    this.loading = false;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.isbnOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  makeDefaultCollaboratori() {
    let info = [];
    info.push(Number(this.book.curatore));
    info.push(Number(this.book.illustratore));
    info.push(Number(this.book.traduttore));
    info.push(Number(this.book.prefazione));
    const sub = this.bookservice
      .getAutoriInfo(info)
      .subscribe((data: Array<description>) => {
        if (data[0].descrizione) {
          this.defaultCollaboratori.curatore.push(data[0]);
        }
        if (data[1].descrizione) {
          this.defaultCollaboratori.illustratore.push(
            data[1]
          );
        }
        if (data[2].descrizione) {
          this.defaultCollaboratori.traduttore.push(
            data[2]
          );
        }
        if (data[3].descrizione) {
          this.defaultCollaboratori.prefazione.push(
            data[3]
          );
        }
      });

    this.subscriptions.push(sub);
  }

  makeDefaultAuthors() {
    for (let i = 1; i < 7; i++) {
      if (Number(this.book['cod_autore_' + i]) !== 0) {
        this.defaultCodiciAutori.push(
          Number(this.book['cod_autore_' + i])
        );
      }
    }
    const sub = this.bookservice
      .getAutoriInfo(this.defaultCodiciAutori)
      .subscribe((data: Array<description>) => {
        this.autoriSelected = data;
      });

    this.subscriptions.push(sub);
  }

  loadBook(oid) {
    const sub = this.bookservice
      .getEntityNovita(oid)
      .subscribe((data: WBookEntity) => {
        const b = new Wbook();
        b.setValues(data);
        this.book = b;
        this.makeDefaultAuthors();
        this.makeDefaultCollaboratori();
        const t = this.book.tags.split(',');
        t.forEach((item) => {
          if (item.replace(' ', '').length > 0)
            this.tags.push({ name: item });
        });
        const interval = setInterval(() => {
          if (this.steps === this.nsteps) {
            this.getTabellaIva(false);
            clearInterval(interval);
          }
        }, 100);
        if (this.usr.profile === 'editore') {
          const giro = Number(
            this.firstGiro.anno +
              Number(this.firstGiro.numero)
          );
          const giroLibro = Number(
            this.book.annoGiro + Number(this.book.nuGiro)
          );
          if (giroLibro === giro || giroLibro > giro) {
            this.disabilitaModifiche = false;
          } else {
            this.disabilitaModifiche = true;
          }
        }
        if (this.book.upload > 0) {
          // esiste la copertina del libro.
          this.loadCopertina();
        }
        if (this.book.sizeScheda) {
          this.loadSchedaNovita();
        }
      });

    this.subscriptions.push(sub);
  }

  loadSchedaNovita() {
    const request = this.bookservice.schedaNovitaRequest(
      this.book.isbn.toString()
    );
    fetch(request, { cache: 'reload' }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          this.novita =
            this.domSanitizer.bypassSecurityTrustResourceUrl(
              URL.createObjectURL(blob)
            );
        });
      }
    });
  }

  loadCopertina() {
    const request = this.bookservice.imageRequest(
      this.book.isbn.toString(),
      'mini'
    );
    fetch(request, { cache: 'reload' }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          this.immagineCopertina =
            URL.createObjectURL(blob);
        });
      }
    });
  }

  setCollaboratoriInput(collaboratori) {
    const collab = {
      CUR: 'curatore',
      ILL: 'illustratore',
      TRA: 'traduttore',
      PRE: 'prefazione',
    };

    for (let i = 1; i <= 5; i++) {
      const collabObj = {
        cod: collaboratori[`cod_ruolo${i}`],
        descrizione: collaboratori[`name${i}`],
        type: collab[collaboratori[`ruolo${i}`]],
      };

      this.collaboratoriSelected.push(collabObj);
    }

    this.collaboratoriSelected =
      this.collaboratoriSelected.filter(
        (collab) => collab.descrizione
      );
  }

  downloadPdf() {
    const request = this.bookservice.schedaNovitaRequest(
      this.book.isbn.toString()
    );
    fetch(request, { cache: 'reload' }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `${this.book.isbn}.pdf`;
          a.click();
        });
      }
    });
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (
      value.trim().length +
        this.tags.toString().length +
        this.tags.length <
      100
    ) {
      // Add our fruit
      if ((value || '').trim()) {
        this.tags.push({ name: value.trim() });
      }
      // Reset the input value
      if (input) {
        input.value = '';
      }
      //  this.book.tags = this.tags.toString();
      this.book.tags = '';
      let i = [];
      this.tags.forEach((item) => {
        i.push(item.name);
      });
      this.book.tags = i.toString();
    }
  }

  removeTag(tag: any): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    this.book.tags = '';
    let i = [];
    this.tags.forEach((item) => {
      i.push(item.name);
    });
    this.book.tags = i.toString();
  }

  setAutori($event) {
    this.autoriSelected = $event;
    this.book.cod_autore_1 = 0;
    this.book.cod_autore_2 = 0;
    this.book.cod_autore_3 = 0;
    this.book.cod_autore_4 = 0;
    this.book.cod_autore_5 = 0;
    this.autoriSelected.forEach((element, index) => {
      let autoreIndex = index + 1;
      this.book['cod_autore_' + autoreIndex] = element.cod;
    });
    console.log(this.autoriSelected);
  }

  setCopertina($event): void {
    this.copertina.delete('image');
    this.copertina.append('image', $event);
    this.book.sizeScheda = $event ? $event.size : 0;
  }

  setPdf($event, tipo: string): void {
    this.schedaNovita.delete('scheda');
    this.schedaNovita.append('scheda', $event);
  }

  private checkPartizione(partizione) {
    //prendo la lunghezza della partizione
    const l = partizione.length;
    //verifico che isbn inizi con la partizione fornita;
    if (
      Number(this.book.isbn.toString().substring(0, l)) ===
      Number(partizione)
    ) {
      return true;
    }
    return false;
  }

  submit() {
    if (!this.ristampa && this.action === 'insert') {
      // controllo l'isbn
      const sub = this.bookservice
        .checkIsbn(this.book.isbn)
        .subscribe((resp: any) => {
          console.log(resp, typeof resp.success);
          if (resp.success === true) {
            this.inserimento();
          } else {
            this.toast.error(
              `Il codice da te inserito risulta già inserito nei nostri archivi, sei pregato di inserire un ISBN diverso per poter continuare con l'inserimento`,
              'Errore ISBN'
            );
          }
        });

      this.subscriptions.push(sub);
    } else {
      this.inserimento();
    }
  }

  inserimento() {
    let error = false;

    // controllo l'isbn ma solo se si tratta di una novita non per le ristampe.
    this.book.richStampa = '0';
    if (!this.book.richStampa) {
      this.toast.error(
        `Ti prego, facci sapere le opzioni di stampa`,
        'Informazione necessaria'
      );
      this.errors.richiestastampa = true;
      error = true;
    }

    if (
      !this.copertina.has('image') &&
      this.action === 'insert' &&
      !this.ristampa
    ) {
      this.toast.error(
        `Almeno un'immagine deve essere caricata`,
        'Informazione necessaria'
      );
      error = true;
    }

    if (
      !this.schedaNovita.has('scheda') &&
      this.action === 'insert' &&
      !this.ristampa
    ) {
      this.toast.error(
        `Il pdf della scheda novità non è stato fornito`,
        'Informazione necessaria'
      );
      error = true;
    }

    // controllo la tabella delle partizioni per il codice EAN */
    let result = false;
    if (this.usr.partizioni) {
      this.usr.partizioni.forEach((partizione) => {
        if (result === false) {
          result = this.checkPartizione(
            partizione.EP_PARTIZIONE
          );
        }
      });
    } else {
      result = true;
    }
    if (!result) {
      this.toast.error(
        `Il codice ISBN ${this.book.isbn.toString()} non risulta essere tra quelli assegnati`,
        'Codice ISBN non valido'
      );
      error = true;
    }

    const payload = this.book.getPayLoad();
    if (!payload.NV_VET_AUT_1) {
      this.toast.info(
        `Se l'autore che cerchi non si trova tra quelli in lista, ne puoi aggiungere uno tramite il pulsante <span class="material-icons">person_add_alt_1</span> `,
        'Non trovi un autore?',
        { enableHtml: true }
      );
      this.toast.error(
        "Per continuare con l'insertimento della novità, aggiungi un autore",
        'Nessun Autore selezionato'
      );
      error = true;
    }

    if (error) {
      return;
    }

    let sub = null;
    if (this.action === 'insert') {
      sub = this.bookservice.insertWbook(payload).subscribe(
        (response: any) => {
          const id = response.data.r.OID;
          this.toast.info(
            'Informazioni base caricate con successo'
          );
          this.saveSchedaNovita(id);
          this.saveCopertina(id);
          this.saveAdditionalImages(id);
          this.OnClickNovita.emit(true);
        },
        (error) => {
          this.toast.error(
            'Si sono verificati degli errori, verificare i dati e riprovare'
          );
          console.error(error);
        }
      );
    } else {
      sub = this.bookservice
        .saveBook(payload, this.oid)
        .subscribe(
          (responce: any) => {
            this.toast.info(
              'Informazioni modificate con successo'
            );
            this.saveCopertina(this.oid);
            this.saveSchedaNovita(this.oid);
            if (this.additionalImages.has('images[]')) {
              this.saveAdditionalImages(this.oid);
            } else {
              this.deleteAdditionalImages(this.book.isbn);
            }
            this.OnClickNovita.emit(true);
          },
          (error) => {
            this.toast.error(
              'Si sono verificati degli errori, verificare i dati e riprovare'
            );
          }
        );
    }

    this.subscriptions.push(sub);
  }

  async saveSchedaNovita(id) {
    if (this.schedaNovita.has('scheda')) {
      const sub = this.bookservice
        .insertSchedaNovita(this.schedaNovita, id)
        .subscribe(
          (data: any) => {
            this.toast.info(
              'Scheda novità inserita con successo'
            );
          },
          (error) => {
            this.toast.error(
              "Si sono verificati degli errori durante l'inserimento della scheda novità"
            );
          }
        );

      this.subscriptions.push(sub);
    }
  }

  async saveCopertina(id) {
    if (this.copertina.has('image')) {
      this.messageservice.setEvent({
        type: 'loadImage',
        event: { ean: this.book.isbn },
      });
      const sub = this.bookservice
        .insertCopertina(this.copertina, id)
        .subscribe(
          (data) => {
            this.toast.info(
              'Immagine copertina inserita con successo'
            );
            this.messageservice.setEvent({
              type: 'reloadImage',
              event: { ean: this.book.isbn },
            });
          },
          (error) => {
            this.toast.error(
              "Si sono verificati degli errori durante il caricamento dell'immagine di copertina"
            );
          }
        );

      this.subscriptions.push(sub);
    }
  }

  async getCollane() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('collane')
    );
    response.json().then((data) => {
      this.collane = data;
      console.log(this.collane);
      this.steps++;
    });
  }

  addCollana() {
    const dialogRef = this.dialog.open(
      NuovaCollanaComponent,
      {
        height: 'auto',
        width: '500px',
      }
    );
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data: string) => {
        if (data) {
          if (data.length > 60) {
            this.toast.error(
              'La collana deve contenere massimo 60 caratteri',
              'Attenzione'
            );
          } else if (
            _.findIndex(this.collane, {
              descrizione: data.toUpperCase(),
            }) > -1
          ) {
            this.toast.info(
              'La collana che stai cercando di inserire è già presente, la selezionamo per te'
            );
            this.book.cod_collana = _.find(this.collane, {
              descrizione: data.toUpperCase(),
            }).cod;
          } else {
            const sub = this.bookservice
              .postResource('collana', {
                descrizione: data,
              })
              .subscribe({
                next: (response: any) => {
                  this.toast.success(
                    'collana inserita con successo, aggiorna la cache per verderla anche in altri form',
                    'Successo'
                  );
                  const cod = response.code;
                  this.collane.push({
                    cod: cod.toString(),
                    descrizione: data.toUpperCase(),
                  });
                  this.book.cod_collana = cod.toString();

                  this.refreshCollane();
                },
                error: (err) =>
                  this.toast.error(
                    'Errore inserimento collana',
                    'Errore'
                  ),
              });

            this.subscriptions.push(sub);
          }
        }
      });
  }

  async refreshCollane() {
    const cache = await caches.open(environment.cache);
    cache.delete(
      this.bookservice.getCollectionCached('collane')
    );
    cache.add(
      this.bookservice.getCollectionCached('collane')
    );
  }

  async getGeneri() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('generi')
    );
    response.json().then((data) => {
      this.generi = data;
      this.steps++;
    });
  }

  async getCategorie() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('categorie')
    );
    response.json().then((data) => {
      this.categorie = data;
      this.steps++;
    });
  }

  async getArgomenti() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('argomenti')
    );
    response.json().then((data) => {
      this.argomenti = data;
      this.steps++;
    });
  }

  async getRilegature() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('rilegature')
    );
    response.json().then((data) => {
      this.rilegature = data;
    });
    this.steps++;
  }

  async getLingue() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('lingue')
    );
    response.json().then((data) => {
      this.lingue = data;
      this.steps++;
    });
  }

  getTabellaIva(toast = true) {
    const sub = this.bookservice
      .getTabellaIva()
      .subscribe((iva: Array<description>) => {
        //this.iva = iva;
        const i = _.findIndex(this.tabellaIva, [
          'cod',
          this.book.cod_categoria,
        ]);
        const row = this.tabellaIva[i];
        iva.forEach((item) => {
          if (parseInt(item.cod) === row.iva) {
            this.aliquotaIva = item;
            this.book.cod_iva = parseInt(item.cod);
            if (toast) {
              this.toast.info(
                item.descrizione,
                'Aliquota IVA'
              );
            }
          }
        });
      });

      this.subscriptions.push(sub);
  }

  clickClose() {
    this.OnClickNovita.emit(true);
  }

  setRuolo(event, field) {
    if (event[0] && event[0].cod) {
      this.book[field] = event[0].cod;
    } else {
      this.book[field] = 0;
    }
    // this.book[field] = event[0].cod;
  }

  aggiungiAutore() {
    const dialogRef = this.dialog.open(
      NuovoAutoreComponent,
      {
        data: { nome: '', cognome: '' },
        height: 'auto',
        width: 'auto',
      }
    );
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data && data.nome && data.cognome) {
          const sub = this.bookservice
            .insertAutore(data)
            .subscribe((resp: any) => {
              this.toast.success(
                `L'autore ${data.nome} ${data.cognome} è stato inserito correttamente`,
                'Inserimento Autore'
              );
              this.autoriSelected.push({
                cod: resp.cod,
                descrizione: `${data.cognome} ${data.nome}`,
              });
              this.setAutori(this.autoriSelected);
            });

            this.subscriptions.push(sub);
        }
      });
  }
  richiestaStampa(event) {
    this.book.richStampa = event.value;
    console.log(event.value);
    this.errors.richiestastampa = false;
  }

  saveAdditionalImages(id) {
    if (this.additionalImages.has('images[]')) {
      const sub = this.bookservice
        .insertAdditionalImages(this.additionalImages, id)
        .subscribe(
          (data) => {
            this.toast.info(
              'Immagini inserite con successo'
            );
          },
          (error) => {
            this.toast.error(
              'Si sono verificati degli errori durante il caricamento delle immagini'
            );
          }
        );

        this.subscriptions.push(sub);
    }
  }

  deleteAdditionalImages(id) {
    const sub = this.bookservice
      .deleteAdditionalImages(id)
      .subscribe((data) => {
        console.log(data);
      });

      this.subscriptions.push(sub);
  }

  setAdditionalImages($event): void {
    if ($event === 0) {
      this.images.pop();
      if (this.images.length === 0) {
        this.additionalImages.delete('images[]');
      }
    } else {
      this.images.push($event);
    }
    this.additionalImages.delete('images[]');
    for (let image of this.images) {
      this.additionalImages.append('images[]', image);
    }
  }

  getPartizioni() {
   const sub =  this.bookservice
      .partizioni(this.book.editore)
      .subscribe((partizioni: Array<Partizioni>) => {
        partizioni.forEach((partizione) => {
          this.isbnOptions.push(partizione.EP_PARTIZIONE);
        });
      });

      this.subscriptions.push(sub);
  }

  /**controllo se l'isbn inserito e' valido */
  checkIsbn(isbn) {
    if (isbn?.length > 0) {
      const tokens = isbn.split('');
      let sum = 0;
      tokens.forEach((item, index) => {
        let multi = index % 2 ? 3 : 1;
        sum += Number(item) * multi;
      });
      return sum % 10 === 0 ? true : false;
    }
    return false;
  }

  //validatore del campo isbn
  isbnFieldValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (this.checkIsbn(control.value)) {
        const sub  =this.bookservice
          .checkIsbn(control.value)
          .subscribe((resp: any) => {
            /**
             * @todo aggiungere loader
             * */
            if (!resp.success) {
              control.setErrors({
                invalidIsbn: 'Isbn già inserito',
              });
            }
          });

          this.subscriptions.push(sub);
      } else {
        return control.value?.length == 13
          ? { invalidIsbn: 'Isbn non valido' }
          : null;
      }
    };
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
