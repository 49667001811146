import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "statoArticolo",
})
export class StatoArticoloPipe implements PipeTransform {
  public stati = [
    { cod: "P", descrizione: "Disponibile" },
    { cod: "E", descrizione: "Esaurito" },
    { cod: "R", descrizione: "Ristampa" },
    { cod: "R", descrizione: "Fuori catalogo" },
    { cod: "A", descrizione: "Annullato" },
    { cod: "I", descrizione: "In produzione" },
    { cod: "S", descrizione: "Speciale" },
    { cod: "U", descrizione: "Da richiamare" },
    { cod: "1", descrizione: "Acconto già fatturato" },
    { cod: "2", descrizione: "Acconto non fatturato" },
    { cod: "3", descrizione: "Sconto incondizionato" },
    { cod: "6", descrizione: "Spese imballo" },
    { cod: "7", descrizione: "Spese trasporto" },
    { cod: "#", descrizione: "Non a magazzino" },
    { cod: "V", descrizione: "Vecchia edizione" },
    { cod: "+", descrizione: "Non più nostro" },
    { cod: "X", descrizione: "Evadibile dal" },
    { cod: "Y", descrizione: "Esaurito pross. F.C." },
    { cod: " ", descrizione: "Disponibile" },
  ];

  transform(value: unknown, ...args: unknown[]): unknown {
    const state = _.filter(this.stati, { cod: value });
    return state[0].descrizione;
  }
}
