import { element } from 'protractor';
import { BookService } from './../../services/book.service';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { dataTable } from '../../models/dataTable.model';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/storage.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Giro } from 'src/app/models/giro.model';
import { iUser } from 'src/app/models/user.model';
import { FormControl, FormGroup } from '@angular/forms';
import { StatsDialogComponent } from 'src/app/components/stats-dialog/stats-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent
  implements OnInit, OnDestroy
{
  @Input() user: iUser = null;

  subscriptions: Subscription[] = [];
  dummyStartDate = new Date();
  dummyEndDate = new Date();
  dateSelezionate(e): void {
    console.log(e);
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private storage: LocalStorageService,
    private bookservice: BookService
  ) {}
  selectedType: number = 88;
  limit = '30';
  types = [
    { cod: 88, description: 'Venduto', checked: true },
    { cod: 89, description: 'Deposito', checked: false },
  ];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  loadingItems = {
    catalogo: false,
    novita: false,
    graficoGiacenze: false,
    graficoVenduto: false,
    tabella: false,
    giri: false,
    toplibri: false,
  };
  giri: Array<Giro>;
  giriCliente: Array<Giro>;
  giroselezionato: Giro;
  libriCatalogo = 0;
  libriNovita = 0;
  tuaVariabile: dataTable = {
    header: ['cod', 'descrizione'],
    rows: [
      [123, 'giuseppe'],
      [124, 'maria'],
      [134, 'data'],
    ],
  };
  dataTable: dataTable = {
    header: ['Titolo', 'Quantità'],
    rows: [],
  };
  chartOption = {
    legend: {
      left: 10,
      data: ['Venduto', 'Deposito'],
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
    },
    yAxis: {
      type: 'value',
    },

    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    series: [
      {
        name: 'Venduto',
        data: [],
        type: 'line',
      },
      {
        name: 'Deposito',
        data: [],
        type: 'line',
      },
    ],
  };

  pieOption = {
    tooltip: {
      trigger: 'item',
    },

    series: [
      {
        name: 'Giacenza',
        type: 'pie',
        radius: '50%',
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  /** data ultima esportazione delle immagini */
  lastData = null;
  execDate = null;

  getSelectedType(event) {
    this.selectedType = event.value;
    this.doReport();
  }

  changeDate() {
    if (
      moment(this.range.value.start).isValid() &&
      moment(this.range.value.end).isValid()
    ) {
      this.doReport();
    }
  }

  async getGiri() {
    console.log('dentro getGiri');
    this.loadingItems.giri = true;
    const sub = this.bookservice
      .getGiri()
      .subscribe((data: Array<Giro>) => {
        console.log(data);
        this.loadingItems.giri = false;
        this.giri = data;
        this.storage.set('firstGiro', data[0] ?? false);
        this.getCatalogo();
        this.getVenduto();
      });

    this.subscriptions.push(sub);
  }

  getVenduto() {
    this.loadingItems.graficoVenduto = true;
    const year = Number(moment().format('Y'));
    const sub = this.bookservice
      .getVenduto(year)
      .subscribe((data: any) => {
        this.loadingItems.graficoVenduto = false;
        data.sellIn.forEach((element, index) => {
          this.chartOption.series[0].data[
            Number(element.mese) - 1
          ] = element.quantita;
        });
        data.sellOut.forEach((element, index) => {
          this.chartOption.series[1].data[
            Number(element.mese) - 1
          ] = element.quantita;
        });
      });

    this.subscriptions.push(sub);
    this.doReport();
    console.log(this.chartOption);
  }

  dataView(opt) {
    let axisData = opt.xAxis[0].data;
    let series = opt.series;
    let table =
      '<table style="width:100%;text-align:center"><tbody><tr>' +
      '<td>Time:</td>' +
      '<td>' +
      series[0].name +
      '</td>' +
      '<td>' +
      series[1].name +
      '</td>' +
      '</tr>';
    for (let i = 0, l = axisData.length; i < l; i++) {
      table +=
        '<tr>' +
        '<td>' +
        axisData[i] +
        '</td>' +
        '<td>' +
        series[0].data[i] +
        '</td>' +
        '<td>' +
        series[1].data[i] +
        '</td>' +
        '</tr>';
    }
    table += '</tbody></table>';
    return table;
  }

  goto(link: string): void {
    this.router.navigate([link]);
  }

  getCatalogo() {
    if (this.user.isEditore()) {
      this.loadingItems.catalogo = true;
      const sub = this.bookservice
        .getBooks()
        .subscribe((data: any) => {
          this.loadingItems.catalogo = false;
          this.libriCatalogo = data.length;
          this.getGiacenza();
        });

      this.subscriptions.push(sub);
    }
  }

  public getGiacenza() {
    this.loadingItems.graficoGiacenze = true;
    const sub = this.bookservice
      .getGiacenza()
      .subscribe((data: Array<any>) => {
        console.log(data);
        data.forEach((element) => {
          this.loadingItems.graficoGiacenze = false;
          this.pieOption.series[0].data.push({
            name: element.descrizione_mag,
            value: element.giacenza,
          });
        });
      });

    this.subscriptions.push(sub);
  }
  /** i top libri */
  async doReport() {
    const start = moment(this.range.value.start).format(
      'YYYYMMDD'
    );
    const stop = moment(this.range.value.end).format(
      'YYYYMMDD'
    );
    this.loadingItems.toplibri = true;

    const sub = this.bookservice
      .getBestSellerBook(
        start,
        stop,
        this.selectedType,
        this.limit
      )
      .subscribe((data: Array<any>) => {
        this.dataTable.rows = [];
        this.dataTable.rowClick = [];
        if (data.length) {
          data.forEach((item) => {
            this.dataTable.rows.push([
              item.titolo,
              Number(item.quantita),
            ]);
            this.dataTable.rowClick.push([
              item.titolo,
              item.codice,
            ]);
          });
        }
        this.loadingItems.toplibri = false;
      });

    this.subscriptions.push(sub);
  }

  openStatsBook(data) {
    const dialogRef = this.dialog.open(
      StatsDialogComponent,
      {
        data: data,
        height: 'auto',
        width: 'auto',
      }
    );
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {});
  }

  dialogStatsContent($event) {
    const dialogTitle = $event[0];
    const tableTitle =
      Number(this.selectedType) === 88
        ? 'Venduto'
        : 'Deposito';
    const start = moment(this.range.value.start).format(
      'YYYYMMDD'
    );
    const stop = moment(this.range.value.end).format(
      'YYYYMMDD'
    );
    let dataTable: dataTable = {
      header: ['Ragione Sociale', 'Citta', 'Quantità'],
      rows: [],
    };

    const sub = this.bookservice
      .getStatsArticolo(
        $event[1],
        start,
        stop,
        this.selectedType
      )
      .subscribe((items: any) => {
        items.forEach((element) => {
          dataTable.rows.push([
            element.ragione_sociale,
            element.citta,
            Number(element.quantita),
          ]);
        });
        const data = {
          data: dataTable,
          dialogTitle: dialogTitle,
          tableTitle: tableTitle,
        };
        this.openStatsBook(data);
      });

    this.subscriptions.push(sub);
  }

  public getDataExport() {
    const sub = this.bookservice
      .getDataExport()
      .subscribe((r: any) => {
        if (r.data_richiesta) {
          this.lastData = moment(r.data_richiesta).format(
            'DD-MM-YYYY'
          );
          this.execDate = moment(r.data_esecuzione).format(
            'DD-MM-YYYY'
          );
        }
      });

    this.subscriptions.push(sub);
  }

  async getClienteGiri() {
    this.loadingItems.giri = true;
    const sub = this.bookservice
      .getGiriByYear(moment().format('YYYY'), 1)
      .subscribe((data: Array<Giro>) => {
        this.loadingItems.giri = true;
        this.giriCliente = data;
        //this.giroselezionato = this.giri[0];
        this.isSelected(data);
      });

    this.subscriptions.push(sub);
  }

  isSelected(giri) {
    let i = null;
    giri.forEach((giro) => {
      i = giri.findIndex(
        (giro) =>
          moment(giro.data_fine_prenotazione).format(
            'YYYY-MM-DD'
          ) >= moment().format('YYYY-MM-DD')
      );
      if (
        giri[i] &&
        giro.descrizione === giri[i].descrizione
      ) {
        this.giroselezionato = giri[i];
        this.storage.set(
          'selectedGiro',
          this.giroselezionato
        );
      }
    });
  }

  ngOnInit(): void {
    if (this.user.isCliente()) {
      this.getClienteGiri();
    }
    this.loadingItems.novita = true;
    if (this.user.isEditore()) {
      const sub = this.bookservice
        .getNovita()
        .subscribe((data: any) => {
          this.libriNovita = data.length;
          this.loadingItems.novita = false;
        });

      this.subscriptions.push(sub);
    }

    const start = moment().subtract(1, 'M').toDate();
    const stop = moment().toDate();
    this.range.controls['start'].setValue(start);
    this.range.controls['end'].setValue(stop);
    if (this.user.isEditore()) {
      this.getGiri();
    }

    if (this.user.isAdmin()) {
      this.getDataExport();
      this.getVenduto();
      this.getClienteGiri();
    }
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
