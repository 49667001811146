import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from './storage.service';
import {SuccessOautResponse} from '../models/oauth.model';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Subscribable} from 'rxjs';

export interface filters {
    name: string,
    value: any
}


@Injectable({
    providedIn: 'root'
})
export class BookService {
    API_BASE: string = environment[environment.env].base_url;
    cache = null;

    constructor(
        private injector: Injector,
        private httpclient: HttpClient,
        private localStorage: LocalStorageService,
        private router: Router
    ) {
        this.initCache();
    }

    public getResource(signature:string){
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/${signature}`, options);
    }

    public postResource(key: string, value) {
        const serviceHeaders = this.getHeaders();
        const options = { headers: serviceHeaders };
    
        return this.httpclient.post(`${this.API_BASE}/${key}`, value, options);
      }


    public saveCatalogoBook(payload, cod) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.patch(`${this.API_BASE}/update-abstract-note/${cod}`, payload, options);
    }

    public getBestSellerBook(start, stop, type, limit, libreria = 0) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/best-sellers/book/${start}/${stop}/${limit}/${type}/${libreria}`, options); 
    }

    public getBestSellerLibrerie(start, stop, type, limit) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/best-sellers/librerie/${start}/${stop}/${limit}/${type}`, options);
    }

    public getBookStats(code, type, onlyCda,start=null, stop = null, ) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const url = (start!==null && stop!==null) ? `${this.API_BASE}/stats/libro/${type}/${code}/${onlyCda}/${start}/${stop}` : 
            `${this.API_BASE}/stats/libro/${type}/${code}/${onlyCda}`;
        return this.httpclient.get(url, options);
    }

    public getZoneEditore(start, stop, limit, type) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/zone-editore/${start}/${stop}/${limit}/${type}`, options);
    }

    public getZoneLibrerieEditore(start, stop, zona, type) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/zone-librerie/${start}/${stop}/${zona}/${type}`, options);
    }

    /**Restituisce le librerie che hanno venduto/deposito un articolo */
    public getStatsArticolo(isbn,start, stop, type) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/stats/librerie/${isbn}/${start}/${stop}/${type}`, options);
    }

    public setRistampa(ean: string) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/ristampa/${ean}`, options);
    }

    public getGiri() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/giri`, options);
    }

    public clienti() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        console.log(`${this.API_BASE}/clienti`);
        return this.httpclient.get(`${this.API_BASE}/clienti`, options);
    }

    public getGiriByYear(year, successivi = 0) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/giri-anno/${year}/${successivi}`, options);
    }

    public getVenduto(year) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/venduto/${year}`, options);
    }

    private async initCache() {
        this.cache = await caches.open(environment.cache);
    }

    public catalogo(page = 1, search = '', order = 'ean') {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/catalogo?page=${page}&order=${order}&filter=${search}`, options);
    }

    public getAutoriInfo(autori) {
        const payload = {
            authors : autori
        };
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.post(`${this.API_BASE}/autori`, payload, options);
    }

    public getBooks() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/catalogo`, options);
    }

    public getNovita() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/libri/novita`, options);
    }

    public getEntityNovita(oid) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/novita/${oid}`, options);
    }
    public getBook(cod: string) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/catalogo/${cod}`, options);
    }


    public getCollectionCached(collectionName): Request  {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token.access_token,
                }
            }
        const jsonResponse = new Request(`${this.API_BASE}/${collectionName}`, options);
        return jsonResponse; 
    }

    public getGiacenza() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/giacenza`, options);
    }

   
    public editori(filter = null) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        if (!filter)
        return this.httpclient.get(`${this.API_BASE}/editori`, options);
        else
        return this.httpclient.get(`${this.API_BASE}/editori?filter=${filter}`, options);
    }

    public partizioni(code) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/partizioni/${code}`, options);
    }
    /** admin part */

    /** ottiene l'ultima data di esportazione delle immagini */
    public getDataExport() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; 
        return this.httpclient.get(`${this.API_BASE}/export/data`, options);
    }
    /**fa partire il job per la generazione del file zip delle immagini per amazon */
    public getExport(data) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; 
        return this.httpclient.get(`${this.API_BASE}/export/download/${data}`, options);
    }
    /** report */
    public editoriReport() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
       
        return this.httpclient.get(`${this.API_BASE}/report/editori`, options);
    }

    public anniReport(editore) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
       
        return this.httpclient.get(`${this.API_BASE}/report/anni/${editore}`, options);
    }


    public mesiReport(editore, anno) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
       
        return this.httpclient.get(`${this.API_BASE}/report/mesi/${editore}/${anno}`, options);
    }

    public downloadReport(editore, anno, mese) { 
        /* const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const requestOptions: object = Object.assign({responseType: 'Blob'}, options);
        return this.httpclient.get(, requestOptions); */
        const ServiceHeaders = this.getHeadersDownload();
        const options = {headers: ServiceHeaders};
        const requestOptions: object = Object.assign({responseType: 'Blob'}, options);

        return this.injector.get(HttpClient).get(`${this.API_BASE}/report/get/${anno}/${mese}/${editore}`, requestOptions);
    } 

    /*******/

    public autori(filter) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/autori?filter=${filter}`, options);
    }
    
    public collane() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/collane`, options);
    }

    public getCollane(filter) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/all-collane?filter=${filter}`, options);
    }

    public generi() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/generi`, options);
    }

    public argomenti(filter?) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/argomenti?filter=${filter}`, options);
    }

    public categorie() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/categorie`, options);
    }

    public rilegature() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/rilegature`, options);
    }

    public lingue() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/lingue`, options);
    }

    public getTabellaIva() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/tabella-iva`, options);
    }

    private getHeaders(): HttpHeaders {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        });

        return header;
    }

    private getHeadersDownload() : HttpHeaders {
        
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        });

        return header;
    }

    public image(ean: string, type: string) {
        //  const options = this.buildRequestOptions({ responseType: 'Blob' });
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const requestOptions: object = Object.assign({responseType: 'Blob'}, options);
        return this.httpclient.get(`${this.API_BASE}/image/ean/${ean}/${type}`, requestOptions);
    }

    public checkIsbn(isbn) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
       
        return this.httpclient.get(`${this.API_BASE}/check/isbn/${isbn}`, options);
    }

    /**
     *
     * @param formData
     * @param id
     */
    public insertCopertina(formData: FormData, id, isean = 0) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/set-copertina/${id}/${isean}`, formData, options);
    }

    /**
     * Inserisce i contenuti multimediali associati ad un titolo
     * Il nome del file rappresenta il titolo a cui associare il file
     */
    uploadMedia(formData: FormData){
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            Accept: 'application/json',
            Authorization: 'Bearer ' + token.access_token,
        });
        const options = {headers: header};
        return this.httpclient.post(`${this.API_BASE}/batch-upload`, formData, options);
    }

    public insertAdditionalImages(formData: FormData,id,isean = 0) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/store-additional-images/${id}/${isean}`, formData, options);
    }

    public viewAdditionalImages(ean) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            }
        }
        const jsonResponse = new Request(`${this.API_BASE}/view-additional-images/${ean}`, options);
        return jsonResponse;
    }
    public deleteAdditionalImages(isbn){
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.delete(`${this.API_BASE}/delete-additional-images/${isbn}`, options);
    }

    public insertSchedaNovita(formData: FormData, id, isean = 0) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/set-scheda-novita/${id}/${isean}`, formData, options);
    }


    public catalogoRequest(): Request {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            }
        }
        const jsonResponse = new Request(`${this.API_BASE}/catalogo`, options);
        return jsonResponse;
    }

    public imageRequest(ean: string, type: string): Request {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            }
        }
        const jsonResponse = new Request(`${this.API_BASE}/image/ean/${ean}/${type}`, options);
        return jsonResponse;
    }

    public schedaNovitaRequest(ean: string): Request {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            }
        }
        const response = new Request(`${this.API_BASE}/novita/ean/${ean}`, options);
        return response;
    }

    

    public insertAutore(payload: any) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
        return this.httpclient.post(`${this.API_BASE}/autore`, payload, options);
    }


    public insertWbook(payload: any) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
        return this.httpclient.post(`${this.API_BASE}/insert-book`, payload, options);
    }

    public saveBook(payload: any, oid) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
        return this.httpclient.patch(`${this.API_BASE}/save-book/${oid}`, payload, options);
    }

    public hadleError(error: Error) {
        // se http status 401 allora elimini tutto lo storage e cache e redirigi nella pagina di login

        console.warn(error);
    }

    public getAllegatoPromozionale(editore, giro) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }
        const response = new Request(`${this.API_BASE}/allegatipromozionali/${editore}/${giro}`, options);
        return response;
    }

    public setAllegatoPromozionale(editore, giro, allegato: FormData) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/set-allegatopromozionale/${editore}/${giro}`, allegato, options);
    }
    public deleteBook(id){
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
        return this.httpclient.delete(`${this.API_BASE}/novita/${id}`, options);
    }

    public getCedola(type, anno, mese, cliente) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const requestOptions: object = Object.assign({responseType: 'blob', filename: 'cedola.xls'}, options);
        return this.httpclient.get(`${this.API_BASE}/cedola/${type}/${anno}/${mese}/${cliente}`, requestOptions);
    }


    public getEditoriCliente(cod_cliente){
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/editori-cliente/${cod_cliente}`, options);
    }
    
    public scaricaArchivio(kind, anno, numero, cliente) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const requestOptions: object = Object.assign({responseType: 'blob', filename: 'cedola.xls'}, options);
        console.log(`${this.API_BASE}/esportazione/${kind}/${anno}/${numero}/${cliente}`);
        return this.httpclient.get(`${this.API_BASE}/esportazione/${kind}/${anno}/${numero}/${cliente}`, requestOptions);
    }

    /**Download unico pdf con le schede novita */
    public donwloadRichSchede(anno:Number,numero: Number){
        const ServiceHeaders = this.getHeadersDownload();
        const options = {headers: ServiceHeaders};
        const requestOptions: object = Object.assign({responseType: 'Blob'}, options);
        return this.injector.get(HttpClient).get(`${this.API_BASE}/pdf/schede-richieste/${anno}/${numero}`, requestOptions);
    }


    /**
     * Inserisce dei file audio e li lega ad un libro
     * 
     * @param formData 
     */
    public insertAudio(formData: FormData, isbn:string) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/audio/${isbn}`, formData, options);
    }

    /**
     * genera il codice qr contentente il link per ascoltare i file audio allegati al libro
     */
    public generateQr(isbn:string){
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        };
        const options = {headers: header};
        //return this.httpclient.get(`${this.API_BASE}/generate-qr/${isbn}`, options);
        return new Request(`${this.API_BASE}/generate-qr/${isbn}`, options);
    }

    public getQr(isbn:string){
        //const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Accept': 'application/json' 
            }
        }
        return new Request(`${this.API_BASE}/qr/${isbn}`, options);
    }
    
    getAudio(isbn){
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/audio/${isbn}`, options);
    }

    deleteAudio(isbn,uuid){
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.delete(`${this.API_BASE}/audio/${isbn}/${uuid}`, options);
    }
}
