import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {DomSanitizer,SafeResourceUrl} from '@angular/platform-browser'
import { BookService } from 'src/app/services/book.service';

@Component({
  selector: 'app-pdf-uploader',
  templateUrl: './pdf-uploader.component.html',
  styleUrls: ['./pdf-uploader.component.scss']
})
export class PdfUploaderComponent implements OnInit {

  @Input() title = '';
  @Output() changeFile = new EventEmitter<object>();
  @Input() public pdf = null; 
  @Output() download = new EventEmitter<boolean>();
  @Input() disabled:boolean = false;
  constructor(private domSanitizer: DomSanitizer ) { }
  maxSize = 10000000;
  error:boolean = false;

  ngOnInit(): void {

  }
  
  clear() {
    this.pdf = null;
  } 

  loadFile(event):void {
    //minore di 10MB
    if(event.target.files[0].size < this.maxSize){
      this.error = false;
      this.changeFile.emit(event.target.files[0]);
      this.pdf = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(event.target.files[0]));
    }else{
      this.error = true;
    }
  }
  apriDocumento() {
    this.download.emit(true);
  }

}
