import { StatsDialogComponent } from '../../components/stats-dialog/stats-dialog.component';
import { dataTable } from './../../models/dataTable.model';
import { BookService } from 'src/app/services/book.service';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { iUser } from 'src/app/models/user.model';
import { MovimentiLibrerieDialogComponent } from 'src/app/components/movimenti-librerie-dialog/movimenti-librerie-dialog.component';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';
@Component({
  selector: 'app-statistiche',
  templateUrl: './statistiche.component.html',
  styleUrls: ['./statistiche.component.scss'],
})
export class StatisticheComponent
  implements OnInit, OnDestroy
{
  @Input() user: iUser = null;
  subscriptions: Subscription[] = [];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  constructor(
    private bookservice: BookService,
    private dialog: MatDialog
  ) {}
  selectedType: number = 88;
  limit = '30';
  types = [
    { cod: 88, description: 'Venduto' },
    { cod: 89, description: 'Deposito' },
  ];
  loadData = false;
  loadDataLibrerie = false;
  loadDataZone = false;
  dataTableLibrerie: dataTable = {
    header: [
      'Ragione Sociale',
      'Indirizzo',
      'Città',
      'Provincia',
      'N',
    ],
    rows: [],
  };

  dataTableZone: dataTable = {
    header: ['Zona', 'Quantita'],
    rows: [],
  };

  dataTable: dataTable = {
    header: ['Titolo', 'Quantità'],
    rows: [],
  };
  graphOption = {
    title: {
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },

    series: [
      {
        type: 'pie',
        radius: [10, 100],
        center: ['50%', '50%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 8,
        },
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  giacenza: dataTable = {
    header: [
      'descrizione',
      'giacenza',
      'ultimo aggiornamento',
    ],
    rows: [],
  };
  ngOnInit(): void {
    const start = moment().subtract(1, 'M').toDate();
    const stop = moment().toDate();
    this.range.controls['start'].setValue(start);
    this.range.controls['end'].setValue(stop);
    this.doReport();
  }

  /** i top libri */
  getBestSellers() {
    const start = moment(this.range.value.start).format(
      'YYYYMMDD'
    );
    const stop = moment(this.range.value.end).format(
      'YYYYMMDD'
    );
    this.loadData = true;
    const sub = this.bookservice
      .getBestSellerBook(
        start,
        stop,
        this.selectedType,
        this.limit
      )
      .subscribe((data: Array<any>) => {
        this.graphOption.series[0].data = [];
        this.dataTable.rows = [];
        this.dataTable.rowClick = [];
        data.forEach((item) => {
          this.dataTable.rows.push([
            item.titolo,
            Number(item.quantita),
          ]);
          this.dataTable.rowClick.push([
            item.titolo,
            item.codice,
          ]);
          this.graphOption.series[0].data.push({
            value: item.quantita,
            name: item.titolo,
          });
        });
        this.loadData = false;
      });

    this.subscriptions.push(sub);
  }
  /**getBestLibrerie */
  getBestLibrerie() {
    const start = moment(this.range.value.start).format(
      'YYYYMMDD'
    );
    const stop = moment(this.range.value.end).format(
      'YYYYMMDD'
    );
    this.loadDataLibrerie = true;
    const sub = this.bookservice
      .getBestSellerLibrerie(
        start,
        stop,
        this.selectedType,
        this.limit
      )
      .subscribe((data: Array<any>) => {
        this.dataTableLibrerie.rows = [];
        this.dataTableLibrerie.rowClick = [];
        data.forEach((item) => {
          this.dataTableLibrerie.rows.push([
            item.ragione_sociale,
            item.indirizzo,
            item.citta,
            item.prov,
            Number(item.quantita),
          ]);
          this.dataTableLibrerie.rowClick.push([
            item.ragione_sociale,
            item.libreria,
          ]);
        });
        this.loadDataLibrerie = false;
      });

    this.subscriptions.push(sub);
  }
  /**
   * do report
   */
  doReport(code = null) {
    if (code) {
      this.selectedType = code;
    }
    this.getBestSellers();
    this.getBestLibrerie();
    this.getZone();
  }

  openStatsBook(data) {
    const dialogRef = this.dialog.open(
      StatsDialogComponent,
      {
        data: data,
        height: 'auto',
        width: 'auto',
      }
    );
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {});
  }

  dialogStatsContent(event) {
    const dialogTitle = event[0];
    const tableTitle =
      Number(this.selectedType) === 88
        ? 'Venduto'
        : 'Deposito';
    const start = moment(this.range.value.start).format(
      'YYYYMMDD'
    );
    const stop = moment(this.range.value.end).format(
      'YYYYMMDD'
    );
    let dataTable: dataTable = {
      header: ['Ragione Sociale', 'Citta', 'Quantità'],
      rows: [],
    };

    const sub = this.bookservice
      .getStatsArticolo(
        event[1],
        start,
        stop,
        this.selectedType
      )
      .subscribe((items: any) => {
        items.forEach((element) => {
          dataTable.rows.push([
            element.ragione_sociale,
            element.citta,
            Number(element.quantita),
          ]);
        });
      });

    this.subscriptions.push(sub);

    const data = {
      data: dataTable,
      dialogTitle: dialogTitle,
      tableTitle: tableTitle,
    };
    this.openStatsBook(data);
  }

  dialogZoneContent(event) {
    const dialogTitle = `Dettaglio ${event[0]}`;
    const tableTitle =
      Number(this.selectedType) === 88
        ? 'Venduto'
        : 'Deposito';
    const start = moment(this.range.value.start).format(
      'YYYYMMDD'
    );
    const stop = moment(this.range.value.end).format(
      'YYYYMMDD'
    );
    let dataTable: dataTable = {
      header: [
        'Ragione Sociale',
        'Indirizzo',
        'Citta',
        'Provincia',
        'Qta',
      ],
      rows: [],
    };

    const sub = this.bookservice
      .getZoneLibrerieEditore(
        start,
        stop,
        event[1],
        this.selectedType
      )
      .subscribe((items: any) => {
        items.forEach((element) => {
          dataTable.rows.push([
            element.ragione_sociale,
            element.indirizzo,
            element.citta,
            element.prov,
            Number(element.quantita),
          ]);
        });
      });

    this.subscriptions.push(sub);

    const data = {
      data: dataTable,
      dialogTitle: dialogTitle,
      tableTitle: tableTitle,
    };
    this.openStatsBook(data);
  }

  openMovimentiBookstore($event) {
    if (this.user.isEditore()) {
      const start = moment(this.range.value.start).format(
        'YYYYMMDD'
      );
      const stop = moment(this.range.value.end).format(
        'YYYYMMDD'
      );
      const dialogRef = this.dialog.open(
        MovimentiLibrerieDialogComponent,
        {
          data: {
            titolo: $event[0],
            start: start,
            stop: stop,
            type: this.selectedType,
            limit: this.limit,
            libreria: $event[1],
          },
          height: 'auto',
          width: 'auto',
        }
      );
      dialogRef
        .afterClosed()
        .toPromise()
        .then((data) => {});
    }
  }
  /**
   * movimenti zone per editore
   */
  getZone() {
    const start = moment(this.range.value.start).format(
      'YYYYMMDD'
    );
    const stop = moment(this.range.value.end).format(
      'YYYYMMDD'
    );
    this.loadDataZone = true;
    const sub = this.bookservice
      .getZoneEditore(
        start,
        stop,
        this.limit,
        this.selectedType
      )
      .subscribe((data: any) => {
        this.dataTableZone.rows = [];
        this.dataTableZone.rowClick = [];
        data.forEach((item) => {
          this.dataTableZone.rows.push([
            item.descrizione_zona
              ? item.descrizione_zona
              : 'Altro',
            Number(item.quantita),
          ]);
          this.dataTableZone.rowClick.push([
            item.descrizione_zona,
            item.zona,
          ]);
        });
        this.loadDataZone = false;
      });

    this.subscriptions.push(sub);
  }

  formatData(data) {
    return moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY');
  }

  ngOnDestroy(): void {
    Utilities.unusubscribeAll(this.subscriptions);
  }
}
