import { iUser } from "./../../models/user.model";
import { ToastrService } from "ngx-toastr";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  Pipe,
  OnDestroy,
} from "@angular/core";
import { LocalStorageService } from "../../services/storage.service";
import { BookService } from "../../services/book.service";
import * as moment from "moment";
import { dataTable } from "src/app/models/dataTable.model";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MediaDialogComponent } from "../media-dialog/media-dialog.component";
import { DatePipe } from "@angular/common";
import { StatoArticoloPipe } from "src/app/pipes/stato-articolo.pipe";
import { Subscription } from "rxjs";
import { Utilities } from "src/app/utilities/utilities.class";
@Component({
  selector: "app-book",
  templateUrl: "./book.component.html",
  styleUrls: ["./book.component.scss"],
})
export class BookComponent implements OnInit, OnDestroy {
  public book: any = null;
  public loading = true;
  public statsLoading = {
    giacenza: false,
    sellin: false,
    sellout: false,
  };
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  user: iUser;
  qrSrc;
  audio = [];
  audioSub;
  subscriptions: Subscription[] = [];



  @Input() code: string = null;
  @Input() onlyCda = 0;

  @Output() clickEvent: any = new EventEmitter<any>();

  ordini: dataTable = {
    header: [
      "Nr Ordine",
      "Data Ordine",
      "Nr Causale",
      "Ragione Sociale",
      "Stato Articolo",
      "Qta Ordine",
    ],
    rows: [],
  };

  giacenza: dataTable = {
    header: ["descrizione", "giacenza", "ultimo aggiornamento"],
    rows: [],
  };

  sellIn: dataTable = {
    header: ["Data", "Codice", "Cliente", "Città", "Quantità"],
    rows: [],
    totale: { col: "Quantità", value: 0 },
  };

  sellOut: dataTable = {
    header: ["Data", "Codice", "Cliente", "Città", "Quantità"],
    rows: [],
    totale: { col: "Quantità", value: 0 },
  };
  selected = new FormControl(0);
  selectedIndex = 0;
 
  
  constructor(
    private bookservice: BookService,
    private toastservice: ToastrService,
    private storage: LocalStorageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getBookDetail(this.code);
    const start = moment().subtract(12, "M").toDate();
    const stop = moment().toDate();
    this.range.controls["start"].setValue(start);
    this.range.controls["end"].setValue(stop);
    const usr = this.storage.get("usr");
    this.user = new iUser(usr);
  }

  getStats(value) {
    this.selectedIndex = value;
    /// giacenza
    if (value === 1) {
      this.statsLoading.giacenza = true;
      this.giacenza.rows = [];
      const sub = this.bookservice
        .getBookStats(this.code, "giacenza", this.onlyCda)
        .subscribe((data: any) => {
          let g = 0;
          data.forEach((element) => {
            this.giacenza.rows.push([
              element.descrizione_mag,
              Number(element.giacenza),
              element.ultimo_aggiornamento != "" &&
              element.ultimo_aggiornamento != null
                ? this.formatData(element.ultimo_aggiornamento)
                : "", // annomeseanno
            ]);
            g = Number(g) + Number(element.giacenza);
          });
          this.statsLoading.giacenza = false;
        });

        this.subscriptions.push(sub);
    }

    if (value == 2) {
      this.statsLoading.sellin = true;
      this.sellIn.rows = [];
      const start = moment(this.range.value.start).format("YYYYMMDD");
      const stop = moment(this.range.value.end).format("YYYYMMDD");
      let totale = 0;
      const sub = this.bookservice
        .getBookStats(this.code, "venduto", false, start, stop)
        .subscribe((data: any) => {
          data.forEach((item) => {
            this.sellIn.rows.push([
              this.formatData(item.data_bolla),
              item.codice_fattura,
              item.ragione_sociale,
              item.citta,
              Number(item.quantita),
            ]);
            totale = Number(item.quantita) + totale;
            this.sellIn.totale.value = totale;
          });

          this.statsLoading.sellin = false;
        });

        this.subscriptions.push(sub);
    }

    if (value == 3) {
      this.statsLoading.sellout = true;
      this.sellOut.rows = [];
      let totale = 0;
      const start = moment(this.range.value.start).format("YYYYMMDD");
      const stop = moment(this.range.value.end).format("YYYYMMDD");
      const sub = this.bookservice
        .getBookStats(this.code, "deposito", false, start, stop)
        .subscribe((data: any) => {
          data.forEach((item) => {
            this.sellOut.rows.push([
              this.formatData(item.data_bolla),
              item.codice_fattura,
              item.ragione_sociale,
              item.citta,
              Number(item.quantita),
            ]);
            totale = Number(item.quantita) + totale;
            this.sellOut.totale.value = totale;
          });
          this.statsLoading.sellout = false;
        });

        this.subscriptions.push(sub);
    }
  }

  /**
   * getBookDetail
   */
  getBookDetail(code) {
    this.loading = true;
    const sub = this.bookservice.getBook(code).subscribe(
      (data: any) => {
        this.book = data;
        this.viewQr();
        this.getPortafoglioOrdini();
        this.loading = false;
      },
      (error: any) => {
        this.bookservice.hadleError(error);
      }
    );

    this.subscriptions.push(sub);
  }

  getPortafoglioOrdini() {
    this.loading = true;
    const sub = this.bookservice
      .getResource(`articoli/in-ordine/${this.book.ean}`)
      .subscribe({
        next: (data: any) => {
          data.map((el) => {
            this.ordini.rows.push([
              el.nr_ordine,
              moment(el.data_ordine).format("DD-MM-YYYY"),
              el.nr_causale,
              el.ragione_sociale,
              new StatoArticoloPipe().transform(el.stato_articolo),
              Number(el.qta_ordine),
            ]);
          });
        },
        error: (err) => console.error(err),
        complete: () => (this.loading = false),
      });

      this.subscriptions.push(sub);
  }

  select(code, description, event) {
    this.clickEvent.emit({
      code: code,
      event: event,
      description: description,
    });
  }

  formatData(data) {
    return moment(data, "YYYY-MM-DD").format("DD-MM-YYYY");
  }
  schedaNovita() {
    this.toastservice.warning("In fase di implementazione");
  }

  viewQr() {
    const request = this.bookservice.getQr(this.book.ean);
    fetch(request, { cache: "reload" })
      .then((response) => response.blob())
      .then((blob) => {
        if (blob.type === "image/png") {
          this.qrSrc = URL.createObjectURL(blob);
        }
      })
      .catch((err) => (this.qrSrc = null));
    this.getAudio();
  }

  deleteAudio() {
    let dialogRef = this.dialog.open(MediaDialogComponent, {
      data: {
        titolo: "Contenuti multimediali",
        audio: this.audio,
        ean: this.book.ean,
      },
      height: "auto",
      width: "550px",
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        this.audioSub.unsubscribe();
        this.getAudio();
      });
  }

  getAudio() {
    const sub = this.audioSub = this.bookservice.getAudio(this.book.ean).subscribe(
      (response: any) => {
        this.audio = Object.values(response);
      },
      (error) => {
        console.error(error);
      }
    );

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
