import { CatalogoClienteComponent } from "./components/cliente/catalogo-cliente/catalogo-cliente.component";
import { HttpClientModule } from "@angular/common/http";
import { RicercaComponent } from "./components/ricerca/ricerca.component";
import { SafeUrlPipe } from "./pipes/safe-resource-url.pipe";
import { SharedModule } from "./shared/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { CatalogoComponent } from "./pages/catalogo/catalogo.component";
import { ImageComponent } from "./components/image/image.component";
import { HeaderComponent } from "./components/header/header.component";
import { UserSettingsComponent } from "./pages/user-settings/user-settings.component";
import { PageComponent } from "./pages/page/page.component";
import { LanciComponent } from "./pages/lanci/lanci.component";
import { StatisticheComponent } from "./pages/statistiche/statistiche.component";
import { AutoreComponent } from "./components/autore/autore.component";
import { ProfiloComponent } from "./pages/profilo/profilo.component";
import { PdfUploaderComponent } from "./components/pdf-uploader/pdf-uploader.component";
import { ChipsComponent } from "./components/chips/chips.component";
import { ImageViewComponent } from "./components/image-view/image-view.component";
import { ToastrModule } from "ngx-toastr";
import { GraficoComponent } from "./components/grafico/grafico.component";
import { TestoComponent } from "./components/testo/testo.component";
import { TabellaComponent } from "./components/tabella/tabella.component";
import { NgxEchartsModule } from "ngx-echarts";
import { BreadcrumpsComponent } from "./components/breadcrumps/breadcrumps.component";
import { BookComponent } from "./components/book/book.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RecapLancioComponent } from "./components/recap-lancio/recap-lancio.component";
import { ElencoNovitaComponent } from "./components/elenco-novita/elenco-novita.component";
import { BookFormComponent } from "./components/book-form/book-form.component";
import { FormatDatePipe } from "./pipes/format-date.pipe";
import { NuovoAutoreComponent } from "./components/nuovo-autore/nuovo-autore.component";
import { ArchivioGiriComponent } from "./components/archivio-giri/archivio-giri.component";
import { DateDiffPipe } from "./pipes/date-diff.pipe";
import { ImagesListComponent } from "./components/images-list/images-list.component";
import { ModalitaStampaComponent } from "./components/modalita-stampa/modalita-stampa.component";
import { ModificaCatalogoComponent } from "./components/modifica-catalogo/modifica-catalogo.component";
import { DeleteQuestionComponent } from "./components/delete-question/delete-question.component";
import { NovitaComponent } from "./components/admin/novita/novita.component";
import { CatalogoComponent as catalogoadmin } from "./components/admin/catalogo/catalogo.component";
import { StatsDialogComponent } from "./components/stats-dialog/stats-dialog.component";
import { LazyUrlComponent } from "./components/lazy-url/lazy-url.component";
import { GenerateReportComponent } from "./components/admin/generate-report/generate-report.component";
import { ExportImagesComponent } from "./components/admin/export-images/export-images.component";
import { GiriClienteComponent } from "./components/cliente/giri-cliente/giri-cliente.component";
import { DocumentDownloadComponent } from "./components/cliente/document-download/document-download.component";
import { NovitaClienteComponent } from "./components/cliente/novita-cliente/novita-cliente.component";
import { ScaricaCedoleComponent } from "./components/admin/scarica-cedole/scarica-cedole.component";
import { InsertBookComponent } from "./components/admin/insert-book/insert-book.component";
import { BookClienteComponent } from "./components/cliente/book-cliente/book-cliente.component";
import { MovimentiLibrerieDialogComponent } from "./components/movimenti-librerie-dialog/movimenti-librerie-dialog.component";
import { ResetPwdComponent } from "./components/reset-pwd/reset-pwd.component";
import { InserimentoContenutiComponent } from "./pages/inserimento-contenuti/inserimento-contenuti.component";
import { DownloadSchedeComponent } from "./components/admin/download-schede/download-schede.component";
import { AudioComponent } from "./pages/inserimento-contenuti/audio/audio.component";
import { MediaDialogComponent } from "./components/media-dialog/media-dialog.component";
import { PrivacyAndCookiesComponent } from "./components/privacy-and-cookies/privacy-and-cookies.component";
import { CookieBarComponent } from "./components/cookie-bar/cookie-bar.component";
import { PortafoglioOrdiniComponent } from "./pages/portafoglio-ordini/portafoglio-ordini.component";
import { MatTableModule } from "@angular/material/table";
import { MaterialModule } from "./material/material.module";
import { ViewOrderComponent } from "./pages/portafoglio-ordini/view-order/view-order.component";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { StatoArticoloPipe } from "./pipes/stato-articolo.pipe";
import { MatSortModule } from "@angular/material/sort";
import localeIt from '@angular/common/locales/fr';
import { registerLocaleData } from "@angular/common";
import { NuovaCollanaComponent } from './components/nuova-collana/nuova-collana.component';
import { SelezionaCollaboratoreComponent } from './components/seleziona-collaboratore/seleziona-collaboratore.component';
registerLocaleData(localeIt);

const ENTRYCOMPONENTS = [
  NuovoAutoreComponent,
  DeleteQuestionComponent,
  StatsDialogComponent,
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CatalogoComponent,
    ImageComponent,
    HeaderComponent,
    UserSettingsComponent,
    PageComponent,
    LanciComponent,
    StatisticheComponent,
    AutoreComponent,
    ProfiloComponent,
    PdfUploaderComponent,
    ChipsComponent,
    ImageViewComponent,
    GraficoComponent,
    TestoComponent,
    TabellaComponent,
    BreadcrumpsComponent,
    BookComponent,
    RecapLancioComponent,
    ElencoNovitaComponent,
    RicercaComponent,
    BookFormComponent,
    FormatDatePipe,
    SafeUrlPipe,
    NuovoAutoreComponent,
    ArchivioGiriComponent,
    DateDiffPipe,
    ImagesListComponent,
    ModalitaStampaComponent,
    ModificaCatalogoComponent,
    DeleteQuestionComponent,
    NovitaComponent,
    catalogoadmin,
    StatsDialogComponent,
    LazyUrlComponent,
    GenerateReportComponent,
    ExportImagesComponent,
    GiriClienteComponent,
    DocumentDownloadComponent,
    CatalogoClienteComponent,
    NovitaClienteComponent,
    ScaricaCedoleComponent,
    InsertBookComponent,
    BookClienteComponent,
    MovimentiLibrerieDialogComponent,
    ResetPwdComponent,
    InserimentoContenutiComponent,
    DownloadSchedeComponent,
    AudioComponent,
    MediaDialogComponent,
    PrivacyAndCookiesComponent,
    CookieBarComponent,
    PortafoglioOrdiniComponent,
    ViewOrderComponent,
    StatoArticoloPipe,
    NuovaCollanaComponent,
    SelezionaCollaboratoreComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    MatTableModule,
    MaterialModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-center",
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    NoopAnimationsModule,
    MatSortModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "it-IT",
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: ENTRYCOMPONENTS,
})
export class AppModule {}
