
<mat-card style=" max-width: 900px; overflow-y: hidden; max-height: 600px"
[ngClass]="{'overflow': selectedFile.length > 0}">
    <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
        <mat-card-subtitle *ngIf="display">Carica le tracce audio da associare ad un titolo.</mat-card-subtitle>
    </mat-card-header>
    <ng-container *ngIf="display">
    
        <mat-card-content align="center">
            <div class="container-fluid">
                <div class="row">
                    
                    <mat-form-field class="example-full-width" style="margin-left: 135px;">
                        <mat-label>Isbn</mat-label>
                        <input matInput [formControl]="isbnControl" [errorStateMatcher]="matcher" maxlength="13">
                        <mat-spinner matSuffix [diameter]="15" class="mx-auto" *ngIf="isbnCheckLoading" style="padding-right: 10px"></mat-spinner>
                        <mat-hint *ngIf="!isbnControl.errors && !this.isbnCheckLoading">
                            <p style="color:green; font-size: 11.5px">Il codice è associato al libro: {{book.titolo}}</p>
                        </mat-hint>
                        <mat-error *ngIf="isbnControl.errors">Isbn non valido</mat-error>
                    </mat-form-field>
                
                    <section class="example-section">
                        <mat-checkbox 
                            class="example-margin" 
                            [(ngModel)]="qr" 
                            [disabled]="!book || qrSrc ? true : false"
                            [matTooltip]="qrSrc ? 'Un codice QR per questo libro è stato già generato. Puoi visualizzare il QR nella pagina del catalogo dedicata al titolo.' : null"
                        >Genera QR</mat-checkbox>
                    </section>

                </div>
            </div>

            <mat-card-actions [@slideInOut]="animationState">
                <input 
                    type="file"
                    multiple
                    #file 
                    style="display:none" 
                    (change)="setFiles($event)" 
                    accept="audio/mpeg">
        
                <button  matSuffix mat-raised-button color="accent" (click)="file.click()" style="margin-top: 30px;">
                    Inserisci file
                    <mat-icon style="font-size:24px" >upload_file</mat-icon>
                </button>
            </mat-card-actions>
        </mat-card-content>
    
        <mat-card-content align="center" *ngIf="selectedFile.length > 0">
            <mat-list>
                <div mat-subheader>Audio selezionati</div>
                <mat-list-item *ngFor="let file of selectedFile">
                    <mat-icon mat-list-icon>music_note</mat-icon>
                    <div mat-line>{{file.name}}</div>
                    <div mat-line> {{formatBytes(file.size) }} </div>
                </mat-list-item>
                
            </mat-list>

            <mat-card-actions>
                <button mat-button color="accent" (click)="confirmUpload()">Conferma</button>
                <button mat-button color="warn" (click)="undoUpload()">Annulla</button>
            </mat-card-actions>
        </mat-card-content>
    </ng-container>

    <mat-card-content *ngIf="!display">
        <mat-spinner diameter="45" style="margin-left: 50%;" *ngIf="uploading"></mat-spinner>
        <div *ngIf="!uploading" align="center">
            <img *ngIf="qrSrc" [src]="qrSrc | safeResourceUrl">
            <br>
            <button mat-button color="accent" (click)="reset()">Continua ad inserire</button>
        </div>
    </mat-card-content>

    <mat-card-footer *ngIf="qr && display">
        <p class="footer-text">Il codice QR verrà generato per il titolo indicato. Tutti i file audio caricati saranno ascoltabili scansionando il codice.</p>
    </mat-card-footer>

</mat-card>