import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OauthService } from '../../services/oauthservice.service';
import { SuccessOautResponse } from '../../models/oauth.model';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss'],
})
export class ResetPwdComponent
  implements OnInit, OnDestroy
{
  public resetPwdForm;
  subscriptions: Subscription[] = [];

  loading = true;
  token: string = null;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private oauth: OauthService
  ) {
    this.resetPwdForm = fb.group({
      nuovaPassword: [
        '',
        [Validators.required, Validators.minLength(8)],
      ],
      ripetiPassword: [
        '',
        [Validators.required, Validators.minLength(8)],
      ],
    });
  }

  ngOnInit(): void {
    this.loading = true;
    const sub = this.activatedRoute.paramMap.subscribe(
      (params) => {
        this.token = params.get('token');
        this.loading = false;
      }
    );

    this.subscriptions.push(sub);
  }

  resetPwd(): void {
    if (
      this.resetPwdForm.value.nuovaPassword ===
      this.resetPwdForm.value.ripetiPassword
    ) {
      const sub = this.oauth
        .restorePassword(
          this.token,
          this.resetPwdForm.value.nuovaPassword
        )
        .subscribe(
          (res: SuccessOautResponse) => {
            this.toast.success(
              'La password è stata aggiornata.'
            );
            this.resetPwdForm.reset();
          },
          (err: any) => {
            // this.toastr.warning(err.error.message);
          }
        );

        this.subscriptions.push(sub);
    } else {
      this.toast.warning('Le due password non coincidono');
    }
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
