import { BookService } from 'src/app/services/book.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'export-images',
  templateUrl: './export-images.component.html',
  styleUrls: ['./export-images.component.scss']
})
export class ExportImagesComponent implements OnInit, OnDestroy {
  @Input() title = 'Esportazione immagini';
  @Input() subtitle = '';
  @Input() secondSubtitle = '';
  @Input() icon = 'camera_alt';
  @Input() type = ''; 
  loading = false;
  @Output() changeDate = new EventEmitter();

  subscription: Subscription = null;

  public options = {
    tipoformato : '',
    dataesportazione : ''
  }
  constructor(private bookservice: BookService, private toastservice: ToastrService,) { }

  ngOnInit(): void {
    
  }


  public export() {
    this.loading = true;
    const selectedDate = moment(this.options.dataesportazione).format('YYYY-MM-DD');
    this.subscription = this.bookservice.getExport(selectedDate).subscribe(data=>{
      this.loading = false;
      this.toastservice.info(`Il link per scaricare l'archivio ti sarà invio via E-mail tra qualche minuto`);
      this.changeDate.emit(true);
    })
  }

  ngOnDestroy(): void {
      Utilities.unsubscribe(this.subscription);
  }
}
