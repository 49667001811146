<div class="ep_card">
    <div class="ep_card-header ep_card-header-icon ep_card-header-warning" >
      <div class="ep_card-icon"><i class="material-icons">sim_card_download</i></div>
      <p class="ep_card-category">Download Documenti </p> 
    </div>
    <div class="ep_card-footer" *ngIf="!loader.pdf && !loader.zip">
          <button mat-raised-button class="download-option" (click)="downloadArchivio('zip')" > 
            <img  src="{{getIcon('zip') }}" class="image" />    &nbsp;Archivio schede novità 
          </button>
          <button mat-raised-button class="download-option" (click)="downloadArchivio('pdf')" > 
            <img   src="{{getIcon('pdf') }}" class="image"   />    &nbsp;Unico pdf delle novità 
          </button>
    </div>
    <div class="ep_card-footer" *ngIf="loader.pdf || loader.zip">
     <div align="center">Download in corso , ti preghiamo di aspettare, potrebbe volerci qualche minuto.</div> 
      <mat-spinner  [diameter]="35" class="mx-auto"   ></mat-spinner>
    </div>
  </div>
  