<div [class]="type">
  <img
    mat-card-image
    [src]="ean ? (frontImageSrc | safeResourceUrl) : 'assets/images/missing.png'"
    *ngIf="!loader"
    onerror="this.src='assets/images/missing.png'"
  />
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loader"
  ></mat-progress-bar>
</div>
