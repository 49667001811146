
<div class="ep_card">
    <div class="ep_card-header ep_card-header-icon" [ngClass]="'ep_card-header-warning'">
      <div class="ep_card-icon"><i class="material-icons">library_books</i></div> 
      <p class="ep_card-category">Archivio Giri</p>
      <h3 class="ep_card-title" *ngIf="giri" >{{giri.length}} giri disponibili</h3>
    </div>
    <div class="ep_card-footer">
      <mat-spinner *ngIf="!giri" [diameter]="30" class="mx-auto"></mat-spinner> 
      <div *ngIf="giri" >
        <table class="mytable" width="100%">
            <tr class="rowTh">
             <th class="cell" >Descrizione</th>
              <th class="cell" >Scadenza</th>
              <th class="cell" >Inizio prenotazione</th>
              <th class="cell" >Fine prenotazione</th>
              <th class="cell">Distrubuzione</th>
              <th class="cell">Mesi consigliati</th>
              <th class="cell">Giorni Rimanenti alla chiusura del giro</th>
            </tr>
            <tr *ngFor="let giro of giri" class="{{isSelected(giro)}}" (click)="selezionaGiro(giro);">
              <td ngClass="cell center"><strong>{{giro.descrizione}}</strong></td>
              <td ngClass="cell center">{{giro.data_scadenza | formatDate}}</td>
              <td ngClass="cell center">{{giro.data_inizio_prenotazione | formatDate}}</td>
              <td ngClass="cell center">{{giro.data_fine_prenotazione | formatDate}}</td>
              <td ngClass="cell center">{{giro.data_distribuzione | formatDate }}</td>
              <td ngClass="cell center">{{giro.mesi_consigliati}}</td>
              <td ngClass="cell center">{{giro.data_scadenza | dateDiff: oggi }}</td>
            </tr>  
          </table> 
      </div>
      <div >
        <app-recap-lancio
        *ngIf="user.isEditore()"
        icon="event"
        description="Ultimo giorno utile per la consegna delle schede novità: {{giroselezionato.data_scadenza | formatDate}}"
        title="{{giroselezionato.descrizione}}"
        inizioPren="{{giroselezionato.data_inizio_prenotazione}}"
        finePren="{{giroselezionato.data_fine_prenotazione}}"
        mesiConsigiati="{{giroselezionato.mesi_consigliati}}"
        volumiInDistrDal="{{giroselezionato.data_distribuzione}}"
        type = 'ep_card-header-primary'
    ></app-recap-lancio> 
      </div>
    </div>
  </div>
  