<section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-10">
        <div class="wrap d-md-flex">
          <div
            class="img"
            style="background-image: url(assets/images/logo.png)"
          ></div>
          <div class="login-wrap p-4 p-md-5">
            <div class="d-flex">
              <div class="w-100">
                <h3 class="mb-0 text-uppercase">UNICOPLI Distributore</h3>
                <div class="badgeLogin">ACCESSO EDITORE</div>
              </div>
            </div>

            <form
              class="signin-form"
              *ngIf="!showResetPwd"
              [formGroup]="form"
              (ngSubmit)="login()"
              @fadein
            >
              <mat-error *ngIf="0">
                The username and password were not recognized
              </mat-error>
              <div class="form-group mb-3">
                <label for="username">Username</label>
                <input
                  matInput
                  type="text"
                  placeholder="Email"
                  class="form-control"
                  formControlName="email"
                  id="username"
                  required
                />
              </div>
              <div class="form-group mb-3">
                <label for="password">Password</label>
                <input
                  matInput
                  type="password"
                  placeholder="Password"
                  class="form-control"
                  formControlName="password"
                  id="password"
                  required
                />
              </div>
              <!--              <div class="d-sm-flex mb-5 align-items-center">-->
              <!--                <span class="ml-auto"><a href="#" class="forgot-pass">Password dimenticata?</a></span>-->
              <!--              </div>-->

              <div class="form-group">
                <input
                  type="submit"
                  value="Log In"
                  class="form-control btn btn-custom rounded submit px-3"
                />
              </div>

              <div class="form-group text-center mt-3">
                <strong
                  class="clickableSpan"
                  (click)="showResetPwd = !showResetPwd"
                  >Password dimenticata?</strong
                >
              </div>

              <div class="form-group text-center mt-3">
                <p class="legalinfo">
                  In questo sito utilizziamo soltanto cookies tecnici per
                  identificare l'utente che ha effettuato l'accesso. Accedendo
                  al tuo account dichiari di aver preso visione della nostra
                  <span class="click" (click)="openDialog()"
                    >Privacy Policy</span
                  >.
                </p>
              </div>
            </form>

            <form
              class="signin-form"
              *ngIf="showResetPwd"
              [formGroup]="formLostPwd"
              (ngSubmit)="resetPwd()"
              @fadein
            >
              <div class="form-group mb-3">
                <p>
                  Inserisci l'indirizzo email che utilizzi per effettuare
                  l'accesso. Riceverai un'email con le istruzioni per recuperare
                  la password.
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="username">Email</label>
                <input
                  matInput
                  type="text"
                  placeholder="Email"
                  class="form-control"
                  formControlName="email"
                  id="emailLostPwd"
                  required
                />
              </div>

              <div class="form-group">
                <input
                  type="submit"
                  value="Recupera Password"
                  class="form-control btn btn-custom rounded submit px-3"
                  [disabled]="formLostPwd.invalid"
                />
              </div>

              <div class="form-group text-center mt-3">
                <strong
                  class="clickableSpan"
                  (click)="showResetPwd = !showResetPwd"
                  >Annulla</strong
                >
              </div>
            </form>
          </div>
        </div>
        <div class="form-group text-center mt-3">
          <p>{{ version }}</p>
        </div>
      </div>
    </div>
    <div class="version">
      {{ version }}
    </div>
  </div>
</section>
