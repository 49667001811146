<mat-dialog-content>
    <h3 class="sectionTitle p-0">Informativa privacy ai sensi degli artt. 13-14 del Regolamento Europeo “Privacy”
        (GDPR)</h3>
    <p>
        I dati personali da Lei inseriti, sono trattati dal proprietario del sito:<br/>
        Unicopli Soc.Coop.<br/>
        Via Alessandro Volta, 4 - 20090 Trezzano sul Naviglio (MI)<br/>
        TEL: +39 02 45703233 - PEC: edizioni.unicopli@legalmail.it<br/>
        in qualità di Titolare, per finalità connesse alla fornitura dei servizi da Lei
        richiesti.
    </p>
    <p>
        Inoltre, i dati da Lei forniti, potrebbero essere trattati, solo su Suo esplicito consenso, al fine di inviarLe
        comunicazioni di natura commerciale via email o sms relativamente ai servizi offerti dal Titolare.
    </p>
    <p>
        Nel primo caso, la base giuridica del trattamento è l’esecuzione di un accordo; nel secondo caso è il consenso.
        I trattamenti avverranno con modalità elettroniche e verranno messi a disposizione delle terze parti
        (Responsabili Esterni del trattamento) nell’ambito dell’Unione Europea o in Paesi extra UE, regolarmente
        contrattualizzate e che offrono adeguate garanzie di sicurezza, necessarie per la fornitura di servizi
        essenziali al soddisfacimento delle Sue esigenze. Tali Responsabili Esterni, il cui elenco è disponibile dietro
        richiesta, possono appartenere a categorie di soggetti che supportano il Titolare nell’erogazione dei servizi
        offerti tramite il presente sito (quali, a titolo esemplificativo: sviluppatori software e gestori di siti web,
        spedizionieri).
    </p>
    <p>
        Nel caso di richiesta di cancellazione dei suoi dati, La informiamo che potrebbe non essere più possibile
        continuare a fornirLe il servizio richiestoci.
    </p>
    <p>
        La informiamo, infine, che potrà proporre reclamo all’Autorità Garante per la Protezione dei Dati, qualora
        ritenesse siano stati violati i suoi diritti.
    </p>

    <h3 class="sectionTitle p-0">Informativa estesa sui cookie</h3>
    <p>
        Questo sito utilizza soltanto cookie tecnici necessari a consentire le funzionalità fondamentali per il sito.
    </p>
    <p>
        I cookies utilizzati in questo sito rientrano nelle categorie descritte di seguito.
    </p>
    <p>
        <strong>Cosa sono i cookies</strong><br/>
        I cookies sono piccoli file di testo che vengono automaticamente posizionati sul PC del navigatore all'interno
        del browser. Essi contengono informazioni di base sulla navigazione in Internet e grazie al browser vengono
        riconosciuti ogni volta che l'utente visita il sito.<br/>
        Di seguito vengono forniti dettagli sui cookie installati da questo sito e indicazioni su come gestire le
        preferenze in merito ad essi.<br/>
    </p>
    <p>
        <strong>Gestione dei cookies</strong><br/>
        Cookie tecnici<br/>
        I cookie tecnici descritti qui di seguito non richiedono consenso e pertanto vengono installati automaticamente
        a seguito dell’accesso al sito.<br/>
        - Cookie necessari al funzionamento: cookies che permettono al sito di funzionare correttamente anche
        consentendo all’utente di avere un’esperienza di navigazione funzionale. Ad esempio, mantengono l'utente
        collegato durante la navigazione evitando che il sito richieda di collegarsi più volte per accedere alle pagine
        successive.
    </p>

</mat-dialog-content>
