<div class="ep_card">
  <div class="ep_card-header ep_card-header-icon" [ngClass]="type">
    <div class="ep_card-icon"><i class="material-icons">{{icon}}</i></div>
    <p class="ep_card-category">{{title}}</p>
    <h3 class="ep_card-title">{{subtitle}}</h3>
  </div>
  <div class="ep_card-footer">
    <mat-spinner *ngIf="loading" [diameter]="30" class="mx-auto"></mat-spinner>
    <div *ngIf="!loading" echarts [options]="chartOption" class="chart" style="height: 320px;"></div> 
  </div>
</div>
