import { BookService } from './../../services/book.service';
import { Wbook } from 'src/app/models/wbook';
import { LocalStorageService } from 'src/app/services/storage.service';
import { Giro } from './../../models/giro.model';
import {
  eventModel,
  MessageService,
} from './../../services/message.service';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-lanci',
  templateUrl: './lanci.component.html',
  styleUrls: ['./lanci.component.scss'],
})
export class LanciComponent implements OnInit, OnDestroy {
  @Input() giro: Giro;

  subscriptions: Subscription[] = [];
  alertClass = '';
  alertClassW = 'warning';
  selectedBook = null;
  action = 'list';
  bookRistampa = null;
  firstGiro = null;

  constructor(
    private messageservice: MessageService,
    private storage: LocalStorageService,
    private bookservice: BookService
  ) {
    this.firstGiro = this.storage.get('firstGiro');
    const i = this.storage.get('ristampaBook');
    if (i !== undefined) {
      const sub = this.bookservice
        .setRistampa(i)
        .subscribe((data: any) => {
          const book = new Wbook();
          book.setValues(data);
          this.bookRistampa = book;
          this.storage.remove('ristampaBook');
          this.action = 'ristampa';
          // this.messageservice.setEvent({type: 'menu', event: { link: 'novita', action: 'ristampa', book : book}});
        });

      this.subscriptions.push(sub);
    }
  }

  ngOnInit(): void {
    const actionStorage = this.storage.get('action');
    if (actionStorage !== undefined) {
      this.storage.remove('action');
      this.action = 'insert';
      console.log('action', this.action);
    }
    /* this.messageservice.listenEvent().subscribe((data:eventModel) => {
            console.log('message service');
             if (data.type === 'menu') {
              if (data.event.link === 'novita') {
                  this.action = data.event.action;
              }
            } 
          });  */
  }

  showBooks(element: string) {
    if (element === 'novita') {
      this.action = 'insert';
    } else {
      this.action = 'list';
      this.selectedBook = null;
    }
  }

  editBook(element: string) {
    this.selectedBook = element;
    this.action = 'edit';
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
