import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { BookService } from 'src/app/services/book.service';
import { MatSort, Sort } from '@angular/material/sort';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-portafoglio-ordini',
  templateUrl: './portafoglio-ordini.component.html',
  styleUrls: ['./portafoglio-ordini.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PortafoglioOrdiniComponent
  implements OnInit, OnDestroy
{
  subscribption: Subscription;
  displayedColumns: string[] = [
    'ean',
    'titolo',
    'qta_ordini_totale',
  ];
  columnsName: string[] = [
    'Ean',
    'Titolo',
    'Qta Ordine Totale',
  ];

  dataSource;
  allData;
  showData = false;
  expandedElement: any | null;
  initData = null;
  loader = false;
  causali: Array<any> = [];
  selected = '0';
  defaultSort = 'editore';
  defaultSortDir = 'asc';

  visualizzaArrivi;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private activatedoute: ActivatedRoute,
    private bookservice: BookService,
    private liveAnnouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.getOrdini();
  }

  /**
   * Prendo tutti gli ordini fatti da quel distributore e creo la tabella
   */
  public getOrdini() {
    this.loader = true;
    this.subscribption = this.bookservice
      .getResource('articoli/in-ordine')
      .subscribe({
        next: (data) => {
          this.allData = data;
          this.dataSource = new MatTableDataSource(
            this.buildDataSource(data)
          );
          this.dataSource.sort = this.sort;
        },
        error: (err) => console.error(err),
        complete: () => (this.loader = false),
      });
  }

  /**
   * Filtro il contenuto della tabella
   * @param event stringa della ricerca
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement)
      .value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Prende i dati non aggregati e gli aggrega per EAN
   * @param data Dati non aggregati
   * @returns i dati aggregati ordinati per titolo ascendente
   */
  buildDataSource(data) {
    let codarticolo = '';
    let dataSource = [];
    // ordino i dati per codice articolo
    data = _.orderBy(data, ['cod_articolo'], ['asc']);

    data.forEach((item) => {
      item.autore ? (item.autore = item.autore.trim()) : '';
      if (codarticolo !== item.cod_articolo) {
        codarticolo = item.cod_articolo;
        let row = {};
        this.displayedColumns.forEach((field: string) => {
          row[field] = item[field];
        });
        row['childs'] = _.filter(data, {
          cod_articolo: codarticolo,
        });

        // da backend i valori degli ordini mi arrivano come stringhe quindi prima li metto insieme
        //const numberValue: string = _.sumBy(row["childs"], "qta_ordine");

        // poi li splitto, li parso in Number e li sommo
        /*  row["qta_ordini_totale"] = _.sum(
          numberValue.split("").map((i) => Number(i))
        );  */

        dataSource.push(row);
      }
    });

    return _.orderBy(
      this.getOrdiniTotale(dataSource),
      ['titolo'],
      ['asc']
    );
  }

  /**
   *
   * @param data totale degli ordini
   * @returns dati con la colonna che contiene il numero totale degli ordini
   */
  getOrdiniTotale(data) {
    data.map((el) => {
      el['qta_ordini_totale'] = _.sumBy(
        el['childs'],
        (child) => {
          return Number(child['qta_ordine']);
        }
      );
    });
    return data;
  }

  /**
   * organizza i dati e switccha le colonne in base ai dati aggregati o no
   * @param event mostrare i dati totali (aggregati) o no
   */
  showCompleteData(event) {
    this.showData = event;
    if (event) {
      this.displayedColumns = [
        'ean',
        'titolo',
        'nr_ordine',
        'nr_causale',
        'ragione_sociale',
        'data_ordine',
        'stato_articolo',
        'qta_ordine',
      ];
      this.columnsName = [
        'Ean',
        'Titolo',
        'Nr Ordine',
        'Nr Causale',
        'Ragione Sociale',
        'Data Ordine',
        'Stato Articolo',
        'Qta Ordine Totale',
      ];
      this.dataSource = new MatTableDataSource(
        _.orderBy(this.allData, ['titolo']['nr_ordine'], [
          'asc',
        ])
      );
    } else {
      this.displayedColumns = [
        'ean',
        'titolo',
        'qta_ordini_totale',
      ];
      this.columnsName = [
        'Ean',
        'Titolo',
        'Qta Ordine Totale',
      ];

      this.dataSource = new MatTableDataSource(
        this.buildDataSource(this.allData)
      );
    }
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.dataSource.sort = this.sort;
    if (sortState.direction) {
      this.liveAnnouncer.announce(
        `Sorted ${sortState.direction}ending`
      );
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  /**
   * Rimuovo le subscription
   */
  ngOnDestroy(): void {
    if (this.subscribption) {
      this.subscribption.unsubscribe();
    }
  }
}
