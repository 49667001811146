import { BookService } from 'src/app/services/book.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dataTable } from 'src/app/models/dataTable.model';
import * as moment from 'moment';

@Component({
  selector: 'app-stats-dialog',
  templateUrl: './stats-dialog.component.html',
  styleUrls: ['./stats-dialog.component.scss']
})
export class StatsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<StatsDialogComponent>,
    private bookservice: BookService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    {
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }

  onUndoClick(){
    this.dialogRef.close();
  }


  formatData(data) {
    return moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY');
  }
}
