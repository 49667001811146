import { environment } from './../../../environments/environment';
import { MessageService, eventModel } from './../../services/message.service';
import { LocalStorageService } from './../../services/storage.service';
import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { iUser, User } from 'src/app/models/user.model';
import { OauthService } from 'src/app/services/oauthservice.service';
import { SuccessOautResponse } from 'src/app/models/oauth.model';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  user: iUser;
  @Input() enableMenu = true;
  color:string = 'primary';
  tabs = [
    {index : 0, name: 'dashboard' }, 
    {index : 1, name: 'catalogo' }, 
    {index : 2, name: 'novita' }, 
    {index : 2, name: 'statistiche' } 
  ];
  domain = environment.domain;
  selected = new FormControl(0);
  giro = null;
  constructor(
    private router: Router,
    private storage: LocalStorageService,
    private oauth: OauthService,
    private messageservice: MessageService
  ) { }

  ngOnInit(): void {
      this.user = new iUser(this.storage.get('usr')); 
      this.giro = this.storage.get('giro');
      const sub = this.messageservice.listenEvent().subscribe((data:eventModel) => {
        if (data.type==='giro') {
          this.giro = data.event.giro;
        }
        if (data.type === 'menu') {
          this.goto(data.event.link);
        }
      });

      this.subscriptions.push(sub);
      this.selectColor();
  }

  goto(link: string): void { 
    const i            = _.findIndex(this.tabs, { name : link});
    this.selected.setValue(this.tabs[i].index);
  }

  logout(): void {
    const token: SuccessOautResponse = this.storage.get('token');
    const sub = this.oauth.logout(token.access_token).subscribe((data) => {
      this.storage.clear();
      this.router.navigate(['login']);
    }, (e) => {
      this.storage.clear();
      this.router.navigate(['login']);
    });

    this.subscriptions.push(sub);
  }

  help(): void {
    window.open(`${this.domain}/help/Introduzione.html`); 
  }
  selectColor(){
    switch(this.user.profile){
      case'editore':
        this.color = 'primary';
        break;
      case'admin':
      this.color = 'warn';
        break;
      case'cliente':
      this.color = 'accent';
        break;
    }
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
