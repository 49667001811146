<div *ngIf="loader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<mat-card
  class="py-1"
  *ngIf="!this.selectedBook && links.length === 0"
>
  <app-ricerca
    (searched)="getSearchType($event)"
    [ricerca]="ricerca"
    (resetSearch)="resetForm($event)"
  ></app-ricerca>
</mat-card>
<mat-card
  class="py-1 mt-2"
  *ngIf="!this.selectedBook && links.length > 0"
>
  <app-breadcrumps
    [links]="links"
    (clicked)="catalogo()"
  ></app-breadcrumps>
</mat-card>
<div
  *ngIf="booksPage"
  class="tp-wrapper"
>
  <div
    class="container-fluid"
    *ngIf="this.selectedBook != null"
  >
    <mat-card class="book">
      <div
        align="right"
        *ngIf="option === 'view'"
      >
        <button
          mat-raised-button
          (click)="toggle(null)"
          color="primary"
        >
          Chiudi Scheda
        </button>
        <button
          mat-raised-button
          (click)="modifica()"
          color="warn"
        >
          Modifica
        </button>
      </div>
      <div
        align="right"
        *ngIf="option === 'edit'"
      >
        <button
          mat-raised-button
          (click)="toggle(null)"
          color="primary"
        >
          Chiudi Scheda
        </button>
        <button
          mat-raised-button
          (click)="confirm()"
          color="warn"
        >
          Conferma
        </button>
      </div>
      <div class="row">
        <div class="col-12">
          <app-book
            *ngIf="option === 'view'"
            [code]="this.selectedBook"
            onlyCda="0"
          ></app-book>
          <app-modifica-catalogo
            *ngIf="option === 'edit'"
            [code]="this.selectedBook"
            [process]="salvaModifiche"
            (close)="toggle(null)"
          ></app-modifica-catalogo>
        </div>
      </div>
    </mat-card>
  </div>

  <div
    class="container-fluid"
    *ngIf="!this.selectedBook"
  >
    <mat-card
      class="book"
      *ngFor="let book of booksPage"
    >
      <div
        class="row"
        [id]="book.cod"
      >
        <div class="col-2">
          <app-image-view
            [ean]="book.ean"
            [type]="'mini'"
          ></app-image-view>
        </div>
        <div class="col-10">
          <h2>{{ book.titolo | uppercase }}</h2>
          <h3>[ISBN]: {{ book.ean }}</h3>
          <p>
            Autore :
            <button
              mat-button
              color="accent"
              (click)="
                getBooksAutore(
                  book.cod_autore_1,
                  book.autore
                )
              "
            >
              {{ book.autore | uppercase }}
            </button>
            Collana
            <button
              mat-button
              color="primary"
              (click)="
                getBooksCollana(
                  book.cod_collana,
                  book.collana
                )
              "
            >
              {{ book.collana }}
            </button>
            <span *ngIf="book.cod_argomento_1 > 0"
              >Argomento:
              <button
                mat-button
                color="primary"
                (click)="
                  getArgomento(
                    book.cod_argomento_1,
                    book.argomento
                  )
                "
              >
                {{ book.argomento }}
              </button>
            </span>
          </p>
          <div class="row">
            <div class="col-3">
              Data di pubblicazione :
              {{ formatData(book.data_pubblicazione) }}
            </div>
            <div class="col-3">
              Categoria : {{ book.categoria }}
            </div>
            <div class="col-3">
              Stato : {{ getStato(book.stato) }}
            </div>
          </div>
          <div align="right">
            <button
              mat-raised-button
              (click)="downloadPdf(book.ean)"
            >
              Download Scheda
            </button>
            <button
              mat-raised-button
              (click)="ristampa(book.ean)"
            >
              Ristampa
            </button>
            <button
              mat-raised-button
              color="primary"
              id=""
              (click)="toggle(book.cod)"
            >
              Info Libro
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<mat-paginator
class="mb-4"
  *ngIf="!loader && !this.selectedBook"
  [length]="books.length"
  [pageSize]="20"
  [pageIndex]="page"
  (page)="changePage($event)"
  [showFirstLastButtons]="true"
>
</mat-paginator>
