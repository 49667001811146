<h1 mat-dialog-title>Inserimento nuova collana</h1>
<div mat-dialog-content class="row"> 
  <mat-form-field class="full-width col-12">
    <mat-label>Collana - Max 60 caratteri</mat-label>
    <input matInput [(ngModel)]="collana">
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Annulla</button>
  <button mat-button [mat-dialog-close]="collana" cdkFocusInitial color="primary" [disabled]="collana.length <= 0">Inserisci</button>
</div>