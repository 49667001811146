import { dataTable } from './../../models/dataTable.model';
import { Giro } from './../../models/giro.model';
import { SuccessOautResponse } from './../../models/oauth.model';
import { BookService } from 'src/app/services/book.service';
import { LocalStorageService } from 'src/app/services/storage.service';
import { MessageService } from './../../services/message.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { description } from '../../models/books.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  DomSanitizer,
  SafeResourceUrl,
} from '@angular/platform-browser';
import { timeInterval, timeout } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-recap-lancio',
  templateUrl: './recap-lancio.component.html',
  styleUrls: ['./recap-lancio.component.scss'],
})
export class RecapLancioComponent
  implements OnInit, OnDestroy
{
  @Input() title = '';
  @Input() description = '';
  @Input() inizioPren = '';
  @Input() finePren = '';
  @Input() mesiConsigiati = null;
  @Input() volumiInDistrDal = null;
  @Input() icon = '';
  @Input() type = '';

  @Output() OnClickNovita = new EventEmitter<string>();
  @Output() OnClickRistampa = new EventEmitter<string>();

  user = null;
  giro: Giro = null;
  allegato = null;
  pdf = null;
  private allegatoPromozionale = new FormData();
  titoliGiro: dataTable = {
    header: ['ISBN', 'Titolo', 'Autore', 'Tipo'],
    rows: [],
  };
  novita = [];
  subscriptions: Subscription[] = [];

  constructor(
    private toast: ToastrService,
    private messageservice: MessageService,
    private storage: LocalStorageService,
    private bookservice: BookService,
    private domSanitizer: DomSanitizer
  ) {
    this.giro = this.storage.get('giro');
    this.getNovitaGiro();
  }

  ngOnInit(): void {
    /* this.user = this.storage.get('usr'); 
        this.giro = this.storage.get('giro');
         this.loadAllegatoPromozionale(); */
    this.getNovitaGiro();
    this.giro = this.storage.get('giro');
    this.user = this.storage.get('usr');
    this.loadAllegatoPromozionale();
    const sub = this.messageservice
      .listenEvent()
      .subscribe((data: any) => {
        if (
          data.type === 'giro' ||
          data.type === 'refresh'
        ) {
          this.user = this.storage.get('usr');
          this.giro = this.storage.get('giro');
          this.loadAllegatoPromozionale();
          this.getNovitaGiro();
        }
      });

    this.subscriptions.push(sub);
  }

  public getNovitaGiro() {
    if (this.giro && this.giro.numero) {
      const sub = this.bookservice.getNovita().subscribe(
        (data: any) => {
          this.novita = _.filter(data, {
            anno_giro: this.giro.anno,
            numero_giro: this.giro.numero.replace('0', ''),
          });
          this.titoliGiro.rows = [];
          this.novita.forEach((item) => {
            //   (item.ristampa === '2') ? item.ristampa = 'Ristampa' : item.ristampa = 'Novità'
            this.titoliGiro.rows.push([
              item.ean,
              item.titolo,
              item.autore,
              item.ristampa === '2' ? 'Ristampa' : 'Novità',
            ]);
          });
        },
        (error: any) => {
          this.bookservice.hadleError(error);
        }
      );
      this.subscriptions.push(sub);
    }
  }

  clickNovita() {
    this.messageservice.setEvent({
      type: 'menu',
      event: { link: 'novita', action: 'insert' },
    });
    this.OnClickNovita.emit('novita');
    this.storage.set('action', 'insert');
  }

  clickRistampa() {
    this.toast.info(
      'Seleziona il libro da ristampare dal tuo catalogo'
    );
    this.messageservice.setEvent({
      type: 'menu',
      event: { link: 'catalogo' },
    });
    //  this.OnClickRistampa.emit('ristampa');
  }

  downloadPdf() {
    const giro = this.giro.anno + this.giro.numero;
    const request =
      this.bookservice.getAllegatoPromozionale(
        this.user.code,
        giro
      );
    fetch(request, { cache: 'reload' }).then(
      (response) => {
        if (response.status != 404) {
          response.blob().then((blob) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.click();
          });
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  loadAllegatoPromozionale() {
    const giro = this.giro.anno + this.giro.numero;
    const request =
      this.bookservice.getAllegatoPromozionale(
        this.user.code,
        giro
      );
    fetch(request, { cache: 'reload' }).then(
      (response) => {
        if (response.status != 404) {
          response.blob().then((blob) => {
            this.pdf =
              this.domSanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL(blob)
              );
          });
        } else {
          this.pdf = null;
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  async saveAllegatoPromozionale() {
    const editore = this.storage.get('usr');
    const annoNumeroGiro =
      this.giro.anno + this.giro.numero;
    if (this.allegatoPromozionale.has('allegato')) {
      const sub = this.bookservice
        .setAllegatoPromozionale(
          editore.code,
          annoNumeroGiro,
          this.allegatoPromozionale
        )
        .subscribe(
          (data: any) => {
            this.toast.info(
              'Scheda novità inserita con successo'
            );
          },
          (error) => {
            this.toast.error(
              "Si sono verificati degli errori durante l'inserimento della scheda novità"
            );
          }
        );

      this.subscriptions.push(sub);
    }
  }
  setPdf($event): void {
    this.allegatoPromozionale.delete('allegato');
    this.allegatoPromozionale.append('allegato', $event);
  }

  ngOnDestroy() {
    Utilities.unusubscribeAll(this.subscriptions);
  }
}
/* 
.then(response => {
                if(response.status === 404){
                    throw new Error('file non trovato');
                }
            
        })*/
