import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BookService } from 'src/app/services/book.service';
import { filter } from 'lodash';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inserimento-contenuti',
  templateUrl: './inserimento-contenuti.component.html',
  styleUrls: ['./inserimento-contenuti.component.scss'],
})
export class InserimentoContenutiComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription[] = [];
  validFile: Array<File> = [];
  invalidFile: Array<File> = [];
  percent: number = 0;
  wooUpload: boolean = false;
  fileNumber: number = 0;

  steps = [
    { title: 'Inserimento contenuti', isActive: true },
    {
      title: 'Controlla i file da inserire',
      isActive: false,
    },
    { title: 'Inserimento in corso', isActive: false },
    { title: 'Inserimento completato', isActive: false },
  ];

  constructor(
    private bookservice: BookService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {}

  /**
   * seleziona i file da caricare
   *
   * @param event
   */
  setFiles(event) {
    this.fileNumber = event.target.files.length;
    Object.values(event.target.files).forEach(
      (file: File) => {
        this.belongToBook(file);
      }
    );
    this.updateStepState(1);
  }

  /**
   * Controlla che il nome del file sia un isbn valido
   *
   * @param file file da uplodare
   */
  belongToBook(file: File) {
    const isbn: string = file.name.substr(0, 13);
    if (isbn.length === 13) {
      this.validFile.push(file);
      /* this.bookservice.checkIsbn(isbn).subscribe((resp:any)=>{
        if(!resp.success){
         
        }else{
          this.invalidFile.push(file);
        }
      }); */
    } else {
      this.invalidFile.push(file);
    }
  }

  /**
   * Procede all' upload dei file validi
   */
  confirmUpload() {
    this.updateStepState(2);

    const formData = new FormData();
    let uploaded: number = 0; //immagini caricate

    this.validFile.forEach((file) => {
      formData.append('file', file);
      const sub = this.bookservice
        .uploadMedia(formData)
        .subscribe(
          (resp) => {
            uploaded++;
            this.percent =
              (uploaded * 100) / this.validFile.length;
            this.observePercent();
            const isbn: string = file.name.substr(0, 13);

            this.wooUpload = true;
            const sub = this.bookservice
              .postResource(`woocommerce/${isbn}`, file)
              .subscribe({
                next: () => {},
                error: (err) => {
                  this.toastService.error(
                    `Errore nel caricamento del titolo su woocommerce`
                  );
                  console.error(err);
                },
                complete: () => (this.wooUpload = false),
              });
          },
          (error) => {
            //this.toastservice.error(`Errore durante il caricamento del file associato al codice ${values.codice}, Riprova.`)
            uploaded++;
            this.percent =
              (uploaded * 100) / this.validFile.length;
            this.observePercent();
          }
        );

      this.subscriptions.push(sub);

      formData.delete('file');
    });
  }
  /**
   * Controllo che la procedura di inseritmento sia terminata
   */
  observePercent() {
    if (this.percent === 100) {
      this.updateStepState(3);
      this.reset();
    }
  }

  /**
   * Ritorna allo steps di inserimento
   */
  undoUpload() {
    this.updateStepState(0);
    this.reset();
  }

  /**
   * Aggiorna lo stato dello step selezionato
   *
   * @param index indice progessivo dello step
   */
  updateStepState(index) {
    this.steps.map((step) => {
      return (step.isActive = false);
    });
    this.steps[index].isActive = true;
  }

  /**
   * Restituisce lo step attivo
   */
  findActiveStep() {
    return filter(this.steps, ['isActive', true])[0];
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      'Bytes',
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
      ' ' +
      sizes[i]
    );
  }

  reset() {
    this.validFile = [];
    this.invalidFile = [];
    this.fileNumber = 0;
    this.percent = 0;
  }

  ngOnDestroy(): void {
    Utilities.unusubscribeAll(this.subscriptions);
  }
}
