import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Giro } from 'src/app/models/giro.model';
import { iUser } from 'src/app/models/user.model';
import { BookService } from 'src/app/services/book.service';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-document-download',
  templateUrl: './document-download.component.html',
  styleUrls: ['./document-download.component.scss'],
})
export class DocumentDownloadComponent implements OnInit, OnDestroy {
  /**
   * serve avere in input il giro per determinare i file di cui bisogna eseguire il download.
   */
  @Input() giro: Array<any>;
  @Input() user: iUser;
  @Input() title: String;

  giroselezionato: Giro = null;
  subscriptions: Subscription[] = [];

  public loader = {
    pdf: false,
    zip: false,
  };
  
  constructor(private bookservice: BookService) {}

  ngOnInit(): void {}

  /**
   * Ritorna se i file sono scaricabili confrontanto la data della scadenza del giro cliente
   * con la data di oggi
   * @returns
   */
  public downloadAvaible(): Boolean {
    return true;
  }
  /**
   * Esegue il download degli archivi novità per tipo (PDF|ZIP)
   * @param tipo
   */
  public async downloadArchivio(tipo: string) {
    this.isSelected(this.giro);
    this.loader[tipo] = true;
    const sub = this.bookservice
      .scaricaArchivio(
        tipo,
        Number(this.giroselezionato.anno),
        Number(this.giroselezionato.numero),
        this.user.code
      )
      .subscribe((data: any) => {
        let contentType =
          tipo === 'pdf'
            ? 'application/pdf'
            : 'application/zip';
        const blob = new Blob([data], {
          type: contentType,
        });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        let ext = tipo === 'pdf' ? '.pdf' : '.zip';
        a.download = `giro_${this.giroselezionato.numero}_${this.giroselezionato.anno}${ext}`;
        this.loader[tipo] = false;
        a.click();
      });

      this.subscriptions.push(sub);
    /*  this.bookservice.scaricaArchivio(tipo, this.giro.anno, this.giro.numero, this.user.code).subscribe((data) => {
      console.log(data);
    }); */
  }

  /**
   * Ritorna un icona in base all'estenzione passata
   * @param ext
   * @returns
   */

  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }

  async isSelected(giri) {
    let i = null;
    giri.forEach((giro) => {
      i = giri.findIndex(
        (giro) =>
          moment(giro.data_fine_prenotazione).format(
            'YYYY-MM-DD'
          ) >= moment().format('YYYY-MM-DD')
      );
      if (
        giri[i] &&
        giro.descrizione === giri[i].descrizione
      ) {
        this.giroselezionato = giri[i];
      }
    });
  }
  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
