
    <mat-card class="py-1" *ngIf="!this.selectedBook  && links.length === 0">
      <app-ricerca (searched)="getSearchType($event)" [ricerca]="ricerca" (resetSearch)="resetForm($event)" (sendSubmit)="submitButton()"
      [singleSearch]="false"></app-ricerca>
    </mat-card>
    <mat-card class="py-1 mt-2" *ngIf="!this.selectedBook && links.length > 0">
      <!-- <app-breadcrumps [links]="links" (clicked)="catalogo()"></app-breadcrumps> -->
    </mat-card> 
    <div *ngIf="loader" align="center" class="loader">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <mat-card class="card" *ngIf="booksPage.length === 0 && !loader " >
          <mat-icon style="vertical-align: middle;" >info</mat-icon>
          <span>                Effettua una ricerca per visualizzare le novità</span>
    </mat-card>
    <mat-card class="card" *ngIf="booksMeta['total'] === 0 && !loader" >
      <mat-icon style="vertical-align: middle;" >error</mat-icon>
      <span>                La ricerca non ha prodotto risultati</span>
  </mat-card>
    <div *ngIf="booksPage" class="tp-wrapper">
      <div class="container-fluid" *ngIf="this.selectedBook != null">
      <mat-card class="book">
        <div align="right" *ngIf="action==='view'" >
          <button mat-raised-button color="primary" (click)="showBooks($event)">Chiudi Scheda</button>
        </div>
        <div class="row">
          <div class="col-12">
            <app-book-cliente *ngIf="action==='view' " [code]="selectedBook"></app-book-cliente>
          </div>
        </div> 
      </mat-card>
    </div>
    
        <div class="container-fluid" *ngIf="!this.selectedBook && !loader">
          <mat-card class="book" *ngFor="let book of booksPage"> 
            <div class="row" [id]="book.attributes.cod">
              <div class="col-2 ">
                <app-image-view [ean]="book.attributes.ean" [type]="'mini'"></app-image-view>
              </div>
              <div class="col-9 ">
                <h2>{{book.attributes.titolo | uppercase}}</h2>
                <h3>[ISBN]: {{book.attributes.ean}}</h3>
                  <p>
                    Editore :
                    <button mat-button color="accent"
                            (click)="searchFromBook('editore', book.attributes.cod_editore)">{{book.attributes.editore | uppercase}}</button>
                    Autore :
                    <button mat-button color="accent"
                            (click)="searchFromBook('autore', book.attributes.cod_autore_1)">{{book.attributes.autore | uppercase}}</button>
                    Collana
                    <button mat-button color="primary"
                            (click)="searchFromBook('collana', book.attributes.cod_collana)">{{book.attributes.collana}}</button>
                    <span *ngIf="book.attributes.cod_argomento_1>0" > 
                      Argomento:
                      <button 
                          mat-button color="primary"
                          (click)="searchFromBook('argomento', book.attributes.cod_argomento_1)">{{book.attributes.argomento}}
                      </button>
                    </span>
                  </p>
                 <div class="row"> 
                  <div class="col-4">Data di pubblicazione : {{formatData(book.attributes.data_pubblicazione)}}</div>
                  <div class="col-4">Categoria : {{book.attributes.categoria}}</div>
                  <div class="col-4">Giro : {{book.attributes.anno_giro+'/'+book.attributes.numero_giro}}</div>
                </div>  
              </div> 
              <div class="col-1">
                <div class="ribbon-content">
                  <div class="ribbon novita px-5" *ngIf="isNovita(book.attributes) && book.attributes.ristampa!=='2' && book.attributes.stato !== 'A'"><span class="uppercase">Novità</span></div>
                  <div class="ribbon uscito px-5" *ngIf="isNovita(book.attributes) && book.attributes.ristampa!=='2' && book.attributes.stato === 'A'"><span class="uppercase">Uscito</span></div>
                  <div class="ribbon ristampa px-5" *ngIf="isNovita(book.attributes) && book.attributes.ristampa==='2'"><span class="uppercase">Ristampa</span></div>
                </div>
              </div>
            </div>   
            <mat-card-actions align="end">
              <!-- (click)="clickEdit(book.attributes.oid)" -->
              <button mat-raised-button color="primary" (click)="clickEdit(book.attributes.oid)">Visualizza</button> 
              <!-- <button mat-raised-button color="warn" id=""(click)="deleteNovita(book.attributes.oid)" >Elimina</button> -->
            </mat-card-actions>
            
          </mat-card>
        </div>
    </div>
    
    
    <mat-paginator *ngIf="!loader && books.length > 0" 
                  [length]="booksMeta['total']"
                  [pageSize]="20"
                  [pageIndex]="page-1 "
                  (page)="changePage($event)"
                  [showFirstLastButtons]="true">
    </mat-paginator>