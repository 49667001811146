import { LocalStorageService } from './../../services/storage.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { iUser } from 'src/app/models/user.model';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  user: iUser;

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("Processing beforeunload...");
    event.returnValue = false;
}
  constructor(
    private storage: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.user = new iUser(this.storage.get('usr'));
  }

  canDeactivate() {
    return confirm("Vuoi veramente uscire dalla pagina?");
  }

}
