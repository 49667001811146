<mat-card class="pdf" matTooltipPosition="left"
          matTooltip="La Scheda Novità è destinata agli agenti di vendita e alle catene di librerie per effettuare la prenotazione del titolo in uscita.">
  <mat-card-title><h3 class="center">{{title}}</h3></mat-card-title>

  <button mat-icon-button style="float: right;" (click)="clear();" *ngIf="pdf && !disabled">
    <mat-icon>clear</mat-icon>
  </button> 

  <button mat-icon-button style="float: right;" (click)="apriDocumento();" *ngIf="pdf">
    <mat-icon>download</mat-icon>
  </button> 
  <embed [attr.src]="pdf" type="application/pdf" width="100%" height="100%" *ngIf="pdf">
   
  <img class="placeholder" src="../../../assets/images/no-scheda.jpg" *ngIf="!pdf" (click)="file.click()"/>
  <input type="file" accept="application/pdf" name="preview" id="preview" #file class="hide"
         (change)="loadFile($event)" [disabled]="disabled">


  <!--    <button mat-button class="full-width" color="primary" (click)="file.click()">-->
  <!--        Seleziona PDF-->
  <!--        <mat-icon>attach_file</mat-icon>-->
  <!--    </button>--> 
<span *ngIf="error" style="color: red;">Il file supera la dimensione massima consentita, inserire un file di massimo 10MB</span>
</mat-card>
