<div class="ep_card">
    <div class="ep_card-header ep_card-header-icon" [ngClass]="type">
      <div class="ep_card-icon"><i class="material-icons">{{icon}}</i></div>
      <p class="ep_card-category">{{title}}</p>
      <h3 class="ep_card-title">Ultima esportazione: {{subtitle}}<br>Dalla data: {{secondSubtitle}}</h3>
    </div>
    <div class="ep_card-footer">
      <div class="row">
        <!-- <div class="col-6">
          <mat-form-field class="full-width"   >
            <mat-label>Seleziona tipo di opzione</mat-label>
            <select matNativeControl  [(ngModel)]="options.tipoformato">
               <option value="amazon">Amazon</option> 
               <option value="arianna">Arianna</option>
            </select> 
          </mat-form-field>
        </div> -->
        <div class="col-12">
          <mat-form-field>
            <mat-label>Dalla data</mat-label>
            <input matInput [matDatepicker]="picker"  [(ngModel)]="options.dataesportazione">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      
      <button mat-raised-button color="primary" *ngIf="options.dataesportazione && !loading" (click)="export()">Scarica archivio</button>
      <button mat-raised-button color="primary" *ngIf="loading" color="link">Sto esportando...
        <mat-icon><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>
      </button>
  </div>