<div class="ep_card">
  <div class="ep_card-header ep_card-header-icon" [ngClass]="type">
    <div class="ep_card-icon"><i class="material-icons">{{icon}}</i></div>
    <p class="ep_card-category">{{title}}</p>
    <h3 class="ep_card-title">{{description}}</h3>
  </div>
  <div class="ep_card-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <h3>Proposte speciali</h3> 
          <app-pdf-uploader title="Allegato Promozionale" (changeFile)="setPdf($event)" [pdf]="pdf" (download)="downloadPdf()"></app-pdf-uploader> 
          <p class="text">Puoi aggiungere un file pdf per indicare le tue proposte speciali di questo giro</p>
          <button mat-raised-button color="primary" (click)="saveAllegatoPromozionale()">
            <span>Salva</span>
          </button>
        </div>
        <div class="col-7">
          <div class="container-fluid">
            <div class="row">
              <div class="col-6">
                <mat-form-field class="full-width">
                  <mat-label>Inizio prenotazioni</mat-label>
                  <input type="text" value="{{  inizioPren |  formatDate}}" matInput disabled >
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="full-width">
                  <mat-label>Fine prenotazioni</mat-label>
                  <input type="text" value="{{finePren |  formatDate}}" matInput disabled>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="full-width">
                  <mat-label>Mesi consigliati di uscita</mat-label>
                  <input type="text" value="{{mesiConsigiati }}" matInput disabled>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="full-width">
                  <mat-label>volumi in distribuzione a partire dal</mat-label>
                  <input type="text" value="{{volumiInDistrDal |  formatDate}}" matInput disabled>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-tabella
                [data]="titoliGiro"
                [loading]=false
                title="Titoli del giro"
                subtitle=""
                icon="grid_on"
                type="ep_card-header-primary"
                style="width: 100%;"></app-tabella>
              </div>
            </div>
          </div>
          
        </div>
        <div class="col-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <button mat-stroked-button color="accent" class="full-width uppercase"
                        style="line-height: 1.4em;padding: 10px 0;"
                        matTooltipPosition="left"
                        matTooltip="Inserisci il materiale promozionale di una novità."
                        (click)="clickNovita()">
                  <mat-icon>add_circle</mat-icon>
                  <br>aggiungi novità
                </button>
              </div>
              <div class="col-12">
                <button mat-stroked-button color="primary" class="full-width uppercase"
                        style="line-height: 1.4em;padding: 10px 0;"
                        matTooltipPosition="left"
                        matTooltip="Inserisci il materiale promozionale di una ristampa di cui vuoi sia fatta la promozione."
                        (click)="clickRistampa()"
                        >
                  <mat-icon>add_circle</mat-icon>
                  <br>aggiungi ristampa
                </button>
              </div>
            </div>
          </div>
        </div>
        
      </div>   
    </div>
  </div>
</div>
