import { OauthService } from './oauthservice.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
//import { ErrorResponce } from '@core/interfaces/common/jsonapi.interface';
import {environment} from 'src/environments/environment';
import {SuccessOautResponse} from "../models/oauth.model";
import { LocalStorageService } from './storage.service';
export interface Filter {
  name: string;
  value: any;
}

@Injectable()
export class JsonapiService {
  API_BASE: string =
    environment[environment.env].base_url + '/' + environment[environment.env].version;

  private errorMessage = '';

  constructor(
    private injector: Injector,
    private router: Router,
    private oauthService: OauthService,
    private st: LocalStorageService,
  ) {}

  public getCollectionPaginated(
    resource: string,
    page: number = 1,
    pageSize: number = 20,
    filters?: Array<Filter>,
    orderby?: string
  ) {
    const serviceHeaders = this.getJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    payload = payload.append('page[number]', page.toString());
    payload = payload.append('page[size]', pageSize.toString());

    const options = { headers: serviceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }

  // esegue una GET una route custom
  public customGetRequest($route) {
    const serviceHeaders = this.getJsonApiHeaders();
    const payload = new HttpParams();
    const options = { headers: serviceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${$route}`, options);
  }

  // resetPassword
  public resetPassword(uri: string, emailAddress: string) {
    const payload = {
      email: emailAddress,
    };
    return this.injector.get(HttpClient).post(uri, payload);
  }

  // ritorna una collection secondo il formato jsonapi
  public getCollection(resource: string, filters?: Array<Filter>, orderby?: string) {
    const serviceHeaders = this.getJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    const options = { headers: serviceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }

  public getEntity(resource: string, id) {
    const serviceHeaders = this.getJsonApiHeaders();
    const options = { headers: serviceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}/${id}`, options);
  }

  public updateEntity(resource: string, id, data) {
    const serviceHeaders = this.getJsonApiHeaders();
    const options = { headers: serviceHeaders };
    return this.injector.get(HttpClient).patch(`${this.API_BASE}/${resource}/${id}`, data, options);
  }

  public postEntity(resource: string, data, addictionalPath?: string) {
    const serviceHeaders = this.getJsonApiHeaders();
    const options = { headers: serviceHeaders };
    let uri = '';
    if (addictionalPath) {
      uri = `${this.API_BASE}/${resource}/${addictionalPath}`;
    } else {
      uri = `${this.API_BASE}/${resource}`;
    }

    return this.injector.get(HttpClient).post(uri, data, options);
  }

  // permette di eseguire una post su una custom path. i data postati possono non essere jsonapi.
  public postEntityCustomPath(data, path?: string) {
    const serviceHeaders = this.getJsonApiHeaders();
    const options = { headers: serviceHeaders };
    const uri = environment[environment.env].base_url + `/${path}`;
    return this.injector.get(HttpClient).post(uri, data, options);
  }

  // delete an entity
  public deleteEntity(resource: string, id) {
    const serviceHeaders = this.getJsonApiHeaders();
    const options = { headers: serviceHeaders };
    const uri = `${this.API_BASE}/${resource}/${id}`;
    return this.injector.get(HttpClient).delete(uri, options);
  }

  // ritorna le headers secondo lo standard jsonapi
  private getJsonApiHeaders(): HttpHeaders {
    const token: SuccessOautResponse = this.st.get('token');
    const header = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
      'Authorization': 'Bearer ' + token.access_token,
    });
    return header;
  }
}
