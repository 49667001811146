<div class="loading-shade" *ngIf="loader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="pag" *ngIf="!loader">
  <div class="row">
    <div class="col-4">
      <mat-form-field style="width: 100%">
        <mat-label>Cerca</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
      </mat-form-field>
    </div>
    <div class="checkbox col">
      <mat-checkbox
        [(ngModel)]="visualizzaArrivi"
        color="primary"
        (ngModelChange)="showCompleteData($event)"
      >
        Visualizza i dati completi
      </mat-checkbox>
    </div>
  </div>

  <div *ngIf="dataSource && !dataSource.data.length">
    <app-alert-box
      type="warning"
      msg="Nessun articolo in ordine"
    ></app-alert-box>
  </div>

  <div
    class="mat-elevation-z8"
    *ngIf="dataSource && dataSource.data && dataSource.data.length"
  >
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="announceSortChange($event)"
      multiTemplateDataRows
      class="mat-elevation-z4"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of displayedColumns; let i = index"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="column">
          {{ columnsName[i] }}
        </th>
        <td mat-cell *matCellDef="let element" class="{{ column }}" [ngClass]="column">
          <span
            *ngIf="column !== 'data_ordine' && column !== 'stato_articolo'"
            >{{ element[column] }}</span
          >
          <span *ngIf="column === 'data_ordine'">
            {{ element[column] | formatDate }}</span
          >
          <span *ngIf="column === 'stato_articolo'">
            {{ element[column] | statoArticolo }}
          </span>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            *ngIf="expandedElement === element && !showData"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <app-view-order [data]="element.childs"></app-view-order>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          Non ho trovato dati per il valore "{{ input.value }}"
        </td>
      </tr>
    </table>
  </div>
</div>
