<table
  mat-table
  [dataSource]="dataSource"
  matSort
  class="mat-elevation-z2 mytable"
>
  <ng-container
    matColumnDef="{{ column }}"
    *ngFor="let column of displayedColumns; let i = index"
  >
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ columnsName[i] }}
    </th>
    <td mat-cell *matCellDef="let element" class="{{ column }}">
      <span *ngIf="column !== 'data_ordine' && column !== 'stato_articolo'">{{
        element[column]
      }}</span>
      <span *ngIf="column === 'data_ordine'">
        {{ element[column] | formatDate }}</span
      >
      <span *ngIf="column === 'stato_articolo'">
        {{ element[column] | statoArticolo }}
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
