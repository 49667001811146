<h1 mat-dialog-title>{{data.dialogTitle}}</h1>
<div mat-dialog-content> 
  <h3>Inserisci le informazioni base</h3>

<div class="row">
  <div class="col-6 " *ngFor="let item of insertFields.fields">
    <mat-form-field class="full-width">
      <mat-label>{{item.field.replace('_',' ')}}</mat-label>
      <select matNativeControl (focus)="getInputName($event)" required
              [formControl]="form" name="{{item.field}}">
        <option *ngFor="let item of item.option.list" [value]="item.cod">
          {{item.descrizione}}
        </option>
      </select>
      <mat-spinner matSuffix [diameter]="15" class="mx-auto" *ngIf="fieldLoader.editori || fieldLoader.giri" ></mat-spinner>
    </mat-form-field>
  </div>
</div>

</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onUndoClick()" cdkFocusInitial >Annulla</button>
  <button [disabled]="disabled.submit"
  mat-button [mat-dialog-close]="data" color="warn" (click)="onInsertClick()">Inserisci</button>
</div>