<h1 mat-dialog-title>{{data.titolo}}</h1>
<div mat-dialog-content> 
    <mat-list>
        <div mat-subheader>Audio</div>
        <mat-list-item *ngFor="let audio of data.audio">
            <mat-icon mat-list-icon>audiotrack</mat-icon>
            <div mat-line>{{audio.file_name}}</div>
            <div mat-line> {{formatBytes(audio.size)}} </div>
            <button mat-icon-button color="link" (click)="deleteAudio(audio)"> 
                <mat-icon>delete</mat-icon>
            </button>
            
        </mat-list-item>
    </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button class="full-width" (click)="onUndoClick()" cdkFocusInitial>Indietro</button>
</div>