import { MessageService } from 'src/app/services/message.service';
import { BookService } from 'src/app/services/book.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent implements OnInit, OnDestroy {
  @Input() ean: string = null;
  @Input() type: string = null;

  loader = true;
  frontImageSrc;
  subscriptions: Subscription[] = [];

  constructor(
    private bookservice: BookService,
    private readonly sanitizer: DomSanitizer,
    private messageservice: MessageService
  ) {}

  ngOnInit(): void {
    this.getImage(this.ean);

    const sub = this.messageservice.listenEvent().subscribe((data) => {
      if (
        data.type === 'reloadImage' &&
        data.event.ean === this.ean
      ) {
        this.getImage(this.ean);
      }
      if (
        data.type === 'loadImage' &&
        data.event.ean === this.ean
      ) {
        this.loader = true;
      }
    });

    this.subscriptions.push(sub);
  }

  async getImage(ean) {
    this.loader = true;
    const imageType = this.type === 'full' ? '' : this.type;
    /*   
    this.bookservice.image(this.ean, imageType).subscribe((data: any) => {
      const mimeType = data.type;
      const blob = new Blob([data], {type: mimeType});
      this.frontImageSrc =  URL.createObjectURL(blob);  
      this.loader = false;
    })     */

    const cache = await caches.open(environment.cache);
    const request = this.bookservice.imageRequest(
      this.ean,
      imageType
    );
    /**
     * da gestire il tempo di cache.
     */
    fetch(request, { cache: 'reload' }) //, {cache: "reload"}
      .then((response) => response.blob())
      .then((blob) => {
        this.frontImageSrc = URL.createObjectURL(blob);
        this.loader = false;
      });
  }

  ngOnDestroy(): void {
    Utilities.unusubscribeAll(this.subscriptions);    
  }
}
