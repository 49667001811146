import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { dataTable } from 'src/app/models/dataTable.model';
import { BookService } from 'src/app/services/book.service';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-movimenti-librerie-dialog',
  templateUrl: './movimenti-librerie-dialog.component.html',
  styleUrls: ['./movimenti-librerie-dialog.component.scss'],
})
export class MovimentiLibrerieDialogComponent
  implements OnInit, OnDestroy
{
  subscription: Subscription = null;
  movimenti: dataTable = {
    header: ['Titolo', 'Quantità'],
    rows: [],
  };

  constructor(
    public dialogRef: MatDialogRef<MovimentiLibrerieDialogComponent>,
    private bookservice: BookService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dialogContent();
    //console.log(this.data);
  }

  onUndoClick() {
    this.dialogRef.close();
  }

  dialogContent() {
    this.movimenti.rows = [];
    this.subscription = this.bookservice
      .getBestSellerBook(
        this.data.start,
        this.data.stop,
        this.data.type,
        this.data.limit,
        this.data.libreria
      )
      .subscribe((data: any) => {
        data.forEach((element) => {
          this.movimenti.rows.push([
            element.titolo,
            Number(element.quantita),
          ]);
        });
      });
  }

  ngOnDestroy(): void {
    Utilities.unsubscribe(this.subscription);
  }
}
