<!--  
  <div class="dialog-md">
  <h2>Seleziona Autore</h2> 
  <mat-form-field  class="full-width" *ngIf="!autore" > 
        <input type="text" 
               matInput 
               [matAutocomplete]="auto" name="autore" (keyup)="getAutori($event.target.value)" >
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setAutore($event)">
          <mat-option *ngFor="let option of autori" [value]="option.cod" >
            {{option.descrizione}}
          </mat-option>
        </mat-autocomplete>
  </mat-form-field>
  <mat-form-field *ngIf="autore" class="full-width">
    <input matInput type="text" name="autore" value="{{autore[0].descrizione}}"  (click)="reset()"  >
  </mat-form-field> 
</div>
<mat-dialog-actions>
  <button  mat-raised-button  mat-dialog-close="{{autore | json}}" color="primary" class="full-width">Conferma</button>
</mat-dialog-actions>
      -->
      <mat-form-field class="full-width">
        <mat-label>{{itemsLabel}}</mat-label>
        <mat-chip-list #chipList aria-label="item selection">
          <mat-chip
            *ngFor="let item of items" 
            [removable]="!disabled"
            (removed)="remove(item)">
            {{item.descrizione}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          
          <input *ngIf="items.length < maxItems" 
            [disabled]= "disabled"
            placeholder="{{newItemLabel}}"
            #itemInput
            [formControl]="itemCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            >
            <button mat-button  matTooltip="Aggiungi" (click)="add()" *ngIf="addElementEnabled" >
            <mat-icon matSuffix>person_add_alt_1</mat-icon>
            </button>
            <mat-hint align="end">{{items.length}} / {{maxItems}}</mat-hint>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" >
          <mat-option *ngFor="let item of filtereditems" [value]="item.cod">
            {{item.descrizione}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field> 