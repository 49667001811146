<div class="container-fluid">
  <form class="row">
    <div
      class="col-2"
      *ngFor="let field of ricerca.fields"
    >
      <div class="row">
        <div
          class="col-10 pr-0"
          *ngIf="field.option.lazyUrl"
        >
          <app-lazy-url
            [itemsLabel]="field.field.replace('_', ' ')"
            (searching)="getValues($event)"
            [filtereditems]="filtereditems"
            (selectedItems)="selected($event)"
            [resetFormField]="reset"
          ></app-lazy-url>
        </div>
        <div
          class="col-10 pr-0"
          *ngIf="field.option.type === 'text'"
        >
          <mat-form-field class="full-width">
            <mat-label>{{
              field.field.replace('_', ' ')
            }}</mat-label>
            <input
              matInput
              [formControl]="form"
              placeholder="{{
                field.field.replace('_', ' ')
              }}"
              type="{{ field.option.type }}"
              name="{{ field.field }}"
              (focus)="getName($event)"
              id="{{ field.field }}"
            />
          </mat-form-field>
        </div>
        <div
          class="col-10 pr-0"
          *ngIf="field.option.list"
        >
          <mat-form-field class="full-width">
            <mat-label>{{
              field.field.replace('_', ' ')
            }}</mat-label>
            <select
              matNativeControl
              [formControl]="form"
              name="{{ field.field }}"
              (focus)="getName($event)"
              id="{{ field.field }}"
            >
              <!-- <option value="0">Tutti</option> -->
              <option
                *ngFor="let item of field.option.list"
                value="{{ item.cod }}"
              >
                {{ item.descrizione }}
              </option>
            </select>
          </mat-form-field>
        </div>
        <div class="col-1 p-0">
          <button
            mat-icon-button
            aria-label="Ordina per {{ field.field }}"
            (click)="orderBy(field.field)"
          >
            <mat-icon
              class="inactive"
              [ngClass]="{
                active: scope === field.field,
                flipped:
                  scope === field.field && order === 'desc'
              }"
              >sort</mat-icon
            >
          </button>
        </div>
      </div>
    </div>

    <div class="reset">
      <button
        mat-icon-button
        (click)="resetFormField()"
        matTooltipPosition="below"
        matTooltip="Reimposta i filtri"
      >
        <mat-icon svgIcon="reset_filter"></mat-icon>
      </button>
    </div>
    <div
      *ngIf="!user.isEditore()"
      class="search"
    >
      <button
        mat-stroked-button
        color="primary"
        (click)="Onsubmit()"
      >
        Cerca
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </form>
</div>
