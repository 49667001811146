<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
                <div class="wrap d-md-flex">
                    <div class="img" style="background-image:url(assets/images/logo.png)">
                    </div>
                    <div class="login-wrap p-4 p-md-5">
                        <div class="d-flex">
                            <div class="w-100">
                                <h3 class="mb-0 text-uppercase">UNICOPLI distributore</h3>
                                <div class="badgeLogin">ACCESSO EDITORE</div>
                                <h5 class="mb-0 text-uppercase">Modifica password</h5>
                            </div>
                        </div>

                        <form class="signin-form"
                              [formGroup]="resetPwdForm"
                              (ngSubmit)="resetPwd()"
                        >
                            <mat-error *ngIf="0">
                                The username and password were not recognized
                            </mat-error>
                            <div class="form-group mb-3">
                                <label for="nuovaPassword">Nuova Password</label>
                                <input matInput type="password" placeholder="Nuova Password" class="form-control"
                                       formControlName="nuovaPassword" id="nuovaPassword" required>
                            </div>
                            <div class="form-group mb-3">
                                <label for="ripetiPassword">Ripeti Password</label>
                                <input matInput type="password" placeholder="Ripeti Password" class="form-control"
                                       formControlName="ripetiPassword" id="ripetiPassword" required>
                            </div>
<!--                            <password-strength-meter [password]="resetPwdForm.value"></password-strength-meter>-->

                            <div class="form-group">
                                <input type="submit" value="Aggiorna Password"
                                       class="form-control btn btn-custom rounded submit px-3"
                                       [disabled]="resetPwdForm.invalid">
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
