import { description } from './../../../models/books.model';
import { Giro } from './../../../models/giro.model';
import { BookService } from 'src/app/services/book.service';
import { JsonapiService } from './../../../services/jsonapi.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Ricerca } from 'src/app/models/ricerca.model';
import * as _ from 'lodash'; 
import * as moment from 'moment';
import { DeleteQuestionComponent } from '../../delete-question/delete-question.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { InsertBookComponent } from '../insert-book/insert-book.component';
import { Wbook } from 'src/app/models/wbook';
import { Console } from 'console';
import { LocalStorageService } from 'src/app/services/storage.service';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';
@Component({
  selector: 'admin-novita',
  templateUrl: './novita.component.html',
  styleUrls: ['./novita.component.scss']
})
export class NovitaComponent implements OnInit, OnDestroy {
  Wbook: Wbook = new Wbook();
  subscriptions: Subscription[] = [];
  public book;
  public books: any = [];
  public booksPage: any = [];
  public links = [];
  salvaModifiche = false;
  //info riguardanti la paginaziine degli oggetti restituiti es. current-page
  public booksMeta: any = {};
  searchFilters: any = {};
  filters = [];
  yearGiro = null;
  public sortField = '-data_pubblicazione';
  public option = 'view';
  public loader = false;
  public page = 1;
  action: string = 'list';
  giri:Array<Giro> = [];
  ricerca: Ricerca = {
    fields: [
        {
          field: 'editore',
          option: {
              type: 'select',
              list:[]
          }
        },
        {
            field: 'giro',
            option: {
                type: 'select',
                list:[]
            }
        },
        {
            field: 'ean',
            option: {
                type: 'text',
            }
        }, 
        {
          field: 'autore',
          option: {
              type: 'lazyUrl',
              lazyUrl: 'autore',
          }
        },
        {
          field: 'collana',
          option: {
              type: 'lazyUrl',
              lazyUrl: 'collana',
          }
        },
        {
          field: 'argomento',
          option: {
              type: 'lazyUrl',
              lazyUrl: 'argomento',
          }
        }, 
        {
          field: 'richiesta_stampa',
          option: {
              type: 'select',
              list: [
                {cod: "2", descrizione: "Si"},
                {cod: "1", descrizione: "No"}
              ],
          }
        },
    ],
};
  selectedBook = null;
  public fieldLoader = {
    editori: false,
    giri: false
  }
  giroselezionato: Giro;
  constructor(
    private jsonapi: JsonapiService,
    private bookservice: BookService,
    private dialog: MatDialog,
    private toastservice: ToastrService,
    private messageservice: MessageService,
    private storage: LocalStorageService,
  ) { }
  

  isLoading() { 
    Object.keys(this.loader).forEach(element => {
      console.log(element);
      if (this.loader[element]) {
        return true;
      }
    });
    return false;
  }

  loadLista(data, field) {
    const i = _.findIndex(this.ricerca.fields, {field : field});
    this.ricerca.fields[i].option.list = [];
    data.forEach(element => {
      this.ricerca.fields[i].option.list.push({ cod: element.cod, descrizione: element.descrizione});
    }); 
  }

  getAllBooks(page, sort?, filters?){
    this.loader = true;
    const sub = this.jsonapi.getCollectionPaginated('novita-admin', page,20,filters, sort).subscribe({
      next: (data: any) => {
        this.books = data.data;
        this.booksMeta = data.meta.page;
        this.booksPage = data.data;
        this.searchFilters = {};
      },
      error: (err) => console.error(err),
      complete: () => this.loader = false,
    })

    this.subscriptions.push(sub);
}

  ngOnInit(): void {
    const date = new Date();
    const fromYear = date.getFullYear() - 5;
    const toYear   = date.getFullYear() + 1;
    let  giriData: Array<description> = [];
    for(let i = fromYear; i<= toYear; i++) {
      giriData.push({ cod: i.toString(), descrizione: i.toString()});
    }
    this.getGiri();
    this.getEditori();
    this.giroselezionato = this.storage.get('selectedGiro');
  }


  getGiri(anno = 0) {
    this.fieldLoader.giri = true;
    const sub = this.bookservice.getGiriByYear(anno).subscribe((giri:Array<Giro>)=> { 
      this.giri = giri;
      const data: Array<description> = [];
      giri.forEach((item:Giro) => { 
          data.push({cod: item.anno + item.numero, descrizione: item.descrizione});
      }) 
        this.loadLista(data, 'giro');
        this.fieldLoader.giri = false;
    });

    this.subscriptions.push(sub);
  }


  submitButton(){
    this.filters = this.searchFilters;
    this.filters.push({name: 'giro_s',value: Number(this.giroselezionato.anno+Number(this.giroselezionato.numero))});
    (this.filters && this.filters.length > 0) ?
    this.getAllBooks(1, this.sortField, this.filters)
    : this.getAllBooks(1, this.sortField)
  }


  getSearchType(data: any) { 
     if (data.event === 'search' ) { 
        this.searchFilters = data.values;
    }
    if(data.event === 'order'){
      if(data.field==='giro'){
        this.sortField = (data.value === 'desc')? '-anno_giro,-numero_giro' : 'anno_giro,numero_giro';
      }
      else{
        this.sortField = (data.value === 'desc')? '-'+data.field : data.field;
      }
    }   
  }


  changePage($event): void {
    this.page = $event.pageIndex+1; 
    this.getAllBooks(this.page, this.sortField, this.filters);
    this.loader = true;
  }

  isNovita(book) { 
    const giro =     Number(this.giroselezionato.anno+Number(this.giroselezionato.numero));
    //const giro = 20213;
    const giroLibro =  Number(book.anno_giro+Number(book.numero_giro));
    if (giroLibro === giro || giroLibro > giro) {
      return true;
    } else {
      return false;
    }
  }

  resetForm(event){
    if(event){
      this.filters = [];
    }
  }

  searchFromBook(field, value){
    const filtro = [{name:field, value:value}];
    this.getAllBooks(1,this.sortField,filtro);
  }

  deleteNovita(id) {
    let book = this.books.find((libro => {
        return libro.attributes.oid === id;
    }))
    const dialogRef = this.dialog.open(DeleteQuestionComponent, {

      data: { delete: true, 
        titolo: book.attributes.titolo, 
        dialogTitle: 'Elimina libro dalle Novità',
        text:'Vuoi davvero eliminare'
      }, 
      height: 'auto',
      width: 'auto',
    });
    dialogRef.afterClosed().toPromise().then((data)=> {
        if(data && data.delete){
            const sub = this.bookservice.deleteBook(id).subscribe({
              next: (response: any) => {
                this.booksPage = [];
                this.booksMeta = [];
                this.books = [];
                this.filters = [];
                this.toastservice.success('Libro eliminato con successo');
                this.messageservice.setEvent({type : 'refresh'});
              },
              error: (err) =>  this.toastservice.error('Errore durante l\'eliminazione, libro non eliminato'),
            })

            this.subscriptions.push(sub);
        }
    });
  }

  getEditori() {
    this.fieldLoader.editori = true;
    this.bookservice.editori('').subscribe((editori:Array<description>)=> {
      this.loadLista(editori, 'editore');
      this.fieldLoader.editori = false;
    });
  }

  clickEdit(id){
    this.action = 'edit';
    this.selectedBook = id;
  }
  showBooks(element){
    console.log(element);
    this.action = 'list';
    this.selectedBook = null;
    this.getAllBooks(this.page, this.sortField, this.filters);
  }
  formatData(data) {
    return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
  }

  insertNovita() {
    const dialogRef = this.dialog.open(InsertBookComponent, {

      data: { dialogTitle: 'Aggiungi una Novità', input:null}, 
      height: 'auto',
      width: 'auto',
    });
    dialogRef.afterClosed().subscribe((data)=> {
      if(data){
        const i = _.findIndex(this.giri, {anno : data.giro.substring(0,4), numero:data.giro.substring(4,6)});
        this.storage.set('giro',this.giri[i]);
        console.log(this.giri[i]);
        this.storage.set('selectedEditore',data.editore);
        this.action = 'insert';
      } 
    });
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
