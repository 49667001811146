import { Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PrivacyAndCookiesComponent} from './components/privacy-and-cookies/privacy-and-cookies.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'editportal-fe';

  constructor(
      public dialog: MatDialog
  ) {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PrivacyAndCookiesComponent);
  }

}
