import { SuccessOautResponse } from './models/oauth.model';
import { LocalStorageService } from './services/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private storage: LocalStorageService,
    private router: Router
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     const requestPath  = next.url;
     if (!this.checkTokenIsValid()){
      this.router.navigateByUrl('login');
      return false;
    } else {
      return true;
    }
  }

  private checkTokenIsValid(): boolean {
    let isValid = false;
    const token: SuccessOautResponse  = this.storage.get('token');
    if (token) {
      const tokenTimestamp = this.storage.get('tokenTimestamp');
      const now = Math.floor(Date.now() / 1000);
      if (now < tokenTimestamp + token.expires_in) {
        isValid = true;
      }
    }
     
      return isValid;
    }
    
    
}
