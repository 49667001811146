//  This file can be replaced during build by using the `fileReplacements` array.
//  `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
//  The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  env: 'stage',
  version: '20240905.1',
  cache:'editportal', 
  domain: 'https://editori.unicopli.it/',
  local: {
    client_id: 2,
    client_secret: 'CjLSSBD0r93HKIokKNGLWj0VmhfhP4md3OV9zXDc',
    oauth_base_url: 'http://127.0.0.1:8000/oauth',
    auth_base_url: 'http://127.0.0.1:8000/api/auth',
    base_url: 'http://127.0.0.1:8000/api',
    domain: 'http://127.0.0.1:8000',
    version: 'v1', 
  },
  stage: {
    client_id: 2,
    client_secret: 'CjLSSBD0r93HKIokKNGLWj0VmhfhP4md3OV9zXDc',
    oauth_base_url: 'https://unicopli-api.dscsrl.it:8000/oauth',
    auth_base_url: 'https://unicopli-api.dscsrl.it:8000/api/auth',
    base_url: 'https://unicopli-api.dscsrl.it:8000/api',
    domain: 'https://unicopli-api.dscsrl.it:8000',
    version: 'v1',
  },
  test: {
    client_id: 2,
    client_secret: 'CjLSSBD0r93HKIokKNGLWj0VmhfhP4md3OV9zXDc',
    oauth_base_url: 'https://stage1.softwareclick.it:8000/oauth',
    auth_base_url: 'https://stage1.softwareclick.it:8000/api/auth',
    base_url: 'https://stage1.softwareclick.it:8000/api',
    domain: 'https://stage1.softwareclick.it:8000',
    version: 'v1',
  },
};
/// 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
