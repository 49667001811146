import { MyErrorStateMatcher } from './../../../components/login/login.component';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
} from '@angular/animations';
import { BookService } from 'src/app/services/book.service';
import { Subscription } from 'rxjs';
import { error } from 'console';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          'max-height': '500px',
          opacity: '1',
          visibility: 'visible',
        })
      ),
      state(
        'out',
        style({
          'max-height': '0px',
          opacity: '0',
          visibility: 'hidden',
        })
      ),
      transition('in => out', [
        group([
          animate(
            '400ms ease-in-out',
            style({
              opacity: '0',
            })
          ),
          animate(
            '600ms ease-in-out',
            style({
              'max-height': '0px',
            })
          ),
          animate(
            '700ms ease-in-out',
            style({
              visibility: 'hidden',
            })
          ),
        ]),
      ]),
      transition('out => in', [
        group([
          animate(
            '1ms ease-in-out',
            style({
              visibility: 'visible',
            })
          ),
          animate(
            '600ms ease-in-out',
            style({
              'max-height': '500px',
            })
          ),
          animate(
            '800ms ease-in-out',
            style({
              opacity: '1',
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class AudioComponent implements OnInit, OnDestroy {
  isbnControl = new FormControl('', [
    Validators.required,
    Validators.minLength(13),
    Validators.maxLength(13),
  ]);
  selectedFile: File[] = [];
  matcher = new MyErrorStateMatcher();
  isbnCheckLoading: boolean = false;
  qr: boolean = false; //valore checkbox 'Genera QR'
  uploading: boolean = false;
  display: boolean = true;
  animationState = 'out';
  title: string = 'Inserisci Audio'; //titolo della card
  formData: FormData = new FormData();
  book: any; //libro associato all'isbn inserito
  qrSrc; //url del codice QR
  subscriptions: Subscription[] = [];

  constructor(private bookservice: BookService) {}

  ngOnInit(): void {
    const sub = this.isbnControl.valueChanges.subscribe(
      (value) => {
        if (value?.length === 13) {
          this.checkIsbn(value);
        }

        if (
          !this.isbnControl.errors &&
          !this.isbnCheckLoading
        ) {
          this.animationState = 'in';
        } else {
          this.animationState = 'out';
        }
      }
    );

    this.subscriptions.push(sub);
  }

  setFiles(event) {
    this.animationState = 'out';
    this.selectedFile = event.target.files;

    for (var i = 0; i < this.selectedFile.length; i++) {
      this.formData.append('audio[]', this.selectedFile[i]);
    }
  }

  confirmUpload() {
    this.uploading = true;
    this.display = false;
    this.sendData();
  }

  undoUpload() {
    this.animationState = 'in';
    this.selectedFile = [];
    this.formData.delete('audio[]');
  }

  reset() {
    this.title = 'Inserisci Audio';
    this.selectedFile = [];
    this.formData.delete('audio[]');
    this.uploading = false;
    this.display = true;
    this.qr = false;
    this.isbnControl.reset();
  }

  toggleShowDiv() {
    this.animationState =
      this.animationState === 'out' ? 'in' : 'out';
  }

  sendData() {
    const sub = this.bookservice
      .insertAudio(this.formData, this.isbnControl.value)
      .subscribe({
        next: (data) => {
          this.title =
            'Inserimento completato con successo';
          if (this.qr) {
            this.generateQr();
          }
        },
        error: (err) => {
          console.error(err);
          this.title =
            "Errore, l'inserimento non è andato a buon fine";
        },
        complete: () => (this.uploading = false),
      });
    this.subscriptions.push(sub);
  }

  generateQr() {
    const request = this.bookservice.generateQr(
      this.isbnControl.value
    );
    fetch(request, { cache: 'reload' })
      .then((response) => response.blob())
      .then((blob) => {
        if (blob.type === 'image/png') {
          this.qrSrc = URL.createObjectURL(blob);
        }
      })
      .catch((error) => console.log('errore'));
  }

  checkIsbn(isbn) {
    this.isbnCheckLoading = true;
    const sub = this.bookservice.checkIsbn(isbn).subscribe(
      (resp: any) => {
        this.isbnCheckLoading = false;
        if (!resp.success) {
          //isbn valido
          this.animationState = 'in';
          this.book = resp.item;
          this.viewQr();
          //9788898609413
        } else {
          this.isbnControl.setErrors({
            invalidIsbn: 'Isbn già inserito',
          });
          this.animationState = 'out';
        }
      },
      (err) => {
        this.isbnCheckLoading = false;
      }
    );

    this.subscriptions.push(sub);
  }

  /**
   * Restutisce il codice png del codice QR
   */
  viewQr() {
    const request = this.bookservice.getQr(this.book.ean);
    fetch(request, { cache: 'reload' })
      .then((response) => response.blob())
      .then((blob) => {
        if (blob.type === 'image/png') {
          this.qrSrc = URL.createObjectURL(blob);
        }
      })
      .catch((error) => console.log('nessun qr trovato'));
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      'Bytes',
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
      ' ' +
      sizes[i]
    );
  }

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
