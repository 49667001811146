import { MessageService } from './../../services/message.service';
import { DeleteQuestionComponent } from './../delete-question/delete-question.component';
import { LocalStorageService } from './../../services/storage.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BookService } from '../../services/book.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Utilities } from 'src/app/utilities/utilities.class';

@Component({
  selector: 'app-elenco-novita',
  templateUrl: './elenco-novita.component.html',
  styleUrls: ['./elenco-novita.component.scss'],
})
export class ElencoNovitaComponent
  implements OnInit, OnDestroy
{
  @Output() OnClickEdit = new EventEmitter<string>();
  public books: any = [];
  public loading = true;
  @Input() firstGiro = null;

  subscriptions: Subscription[] = [];

  constructor(
    private bookservice: BookService,
    private storage: LocalStorageService,
    private dialog: MatDialog,
    private toastservice: ToastrService,
    private messageservice: MessageService
  ) {}

  ngOnInit(): void {
    this.getNovita();
  }

  isNovita(book) {
    const giro = Number(
      this.firstGiro.anno + Number(this.firstGiro.numero)
    );
    const giroLibro = Number(
      book.anno_giro + Number(book.numero_giro)
    );
    if (giroLibro === giro || giroLibro > giro) {
      return true;
    } else {
      return false;
    }
  }

  getNovita() {
    this.loading = true;
    const sub = this.bookservice.getNovita().subscribe(
      (data: any) => {
        this.loading = false;
        this.books = data;
      },
      (error: any) => {
        this.bookservice.hadleError(error);
      }
    );

    this.subscriptions.push(sub);
  }

  clickEdit(cod: string) {
    this.OnClickEdit.emit(cod);
  }

  deleteNovita(id) {
    let book = this.books.find((libro) => {
      return libro.oid === id;
    });
    const dialogRef = this.dialog.open(
      DeleteQuestionComponent,
      {
        data: {
          delete: true,
          titolo: book.titolo,
          dialogTitle: 'Elimina libro dalle Novità',
          text: 'Vuoi davvero eliminare',
        },
        height: 'auto',
        width: 'auto',
      }
    );
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data && data.delete) {
          this.bookservice.deleteBook(id).subscribe(
            (response: any) => {
              this.getNovita();
              this.toastservice.success(
                'Libro eliminato con successo'
              );
              this.messageservice.setEvent({
                type: 'refresh',
              });
            },
            (error) => {
              this.toastservice.error(
                "Errore durante l'eliminazione, libro non eliminato"
              );
            }
          );
        }
      });
  }
  formatData(data) {
    return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
  }
  

  ngOnDestroy(): void {
      Utilities.unusubscribeAll(this.subscriptions);
  }
}
